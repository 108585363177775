import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import { useEffect, useMemo, useState } from 'react';
import { useUserContext } from '@/context/UserContext';
import { Customer, User, ValidatedDiscountCode } from '@/modules/apiTypes';
import { StrapiCheckoutSectionData } from '@/modules/strapiTypes';
import { StrapiRichText } from '@/strapiComponents/StrapiRichText';
import PaymentOptions from './PaymentOptions';
import BillingAddressFields from './BillingAddressFields';
import InvoiceCheckoutFields from './InvoiceCheckoutFields';
import CompanyCheckoutSwitch from '@/components/pageStore/sectionHLRCheckout/partials/CompanyCheckoutSwitch';
import DeliveryAddressFields from './DeliveryAddressFields';
import ShippingAlternatives from './ShippingAlternatives';
import ConfirmCheckout from './ConfirmCheckout';

interface CheckoutAddressFormProps {
  data: StrapiCheckoutSectionData;
  handleFormSubmit(formValues: FormValues): void;
  stateFormValues: FormValues | undefined;
  discountCode?: ValidatedDiscountCode | undefined;
}

export function CheckoutForm({
  data,
  handleFormSubmit,
  stateFormValues,
  discountCode,
}: CheckoutAddressFormProps) {
  const { userInfo, selectedCustomer } = useUserContext();
  const [initialValues, setInitialValues] =
    useState<ReturnType<typeof getInitialValues>>();
  const validationSchema = useMemo(() => getFormSchema(data), [data]);

  useEffect(() => {
    setInitialValues(getInitialValues(userInfo, selectedCustomer));
  }, [userInfo, selectedCustomer]);

  const handleSubmit = (formValues: FormValues) => {
    handleFormSubmit(formValues);
  };

  return (
    <>
      <h2>{data.fields_form_title}</h2>
      <StrapiRichText
        additionalClassNames="text-body-hero my-3 max-w-4xl md:my-5"
        richText={data.fields_form_description}
      />
      {initialValues !== undefined && (
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          enableReinitialize={true}
        >
          {({
            submitForm,
            errors,
            touched,
            values,
            setFieldValue,
            isValid,
          }) => {
            persistFormValuesToLocalStorage(values);
            return (
              <>
                <div className="my-10">
                  <CompanyCheckoutSwitch
                    companyCheckout={values.companyCheckout}
                    setValue={(value) =>
                      setFieldValue('companyCheckout', value)
                    }
                    companyCustomerLabel={
                      data.fields_company_selection ?? 'Företag'
                    }
                    privateCustomerLabel={
                      data.fields_private_selection ?? 'Privat'
                    }
                  />
                </div>

                <PaymentOptions
                  touched={touched}
                  errors={errors}
                  values={values}
                  data={data}
                />

                <BillingAddressFields
                  data={data}
                  companyCheckout={values.companyCheckout}
                />

                {values.paymentOption === 'invoice' && (
                  <InvoiceCheckoutFields {...data} />
                )}

                <label className="group flex cursor-pointer items-center gap-4 pb-10 pt-6">
                  <Field
                    className="form-checkbox"
                    type="checkbox"
                    name="useSameAddress"
                  />
                  <h3 className="group-hover:underline">
                    {data.fields_same_address_toggle_text}
                  </h3>
                </label>

                <div className="border-b border-hlr-gunmetal">
                  {!values.useSameAddress && (
                    <DeliveryAddressFields
                      data={data}
                      companyCheckout={values.companyCheckout}
                    />
                  )}
                </div>
                <ShippingAlternatives
                  data={data}
                  customerType={values.companyCheckout ? 'company' : 'private'}
                />
                {stateFormValues !== values && (
                  <ConfirmCheckout
                    data={data}
                    submitForm={submitForm}
                    values={values}
                    setFieldValue={setFieldValue}
                    isValid={isValid}
                    discountCode={discountCode}
                  />
                )}
              </>
            );
          }}
        </Formik>
      )}
    </>
  );
}

function getFormSchema(data: StrapiCheckoutSectionData) {
  const addressFields = {
    firstName: Yup.string().required(data.error_field_required_message),
    lastName: Yup.string().required(data.error_field_required_message),
    address: Yup.string().required(data.error_field_required_message),
    address2: Yup.string(),
    postNr: Yup.string().required(data.error_field_required_message),
    city: Yup.string().required(data.error_field_required_message),
    country: Yup.string().required(data.error_field_required_message),
  };

  return Yup.object({
    paymentOption: Yup.string().required(data.error_field_required_message),
    useSameAddress: Yup.boolean(),
    companyCheckout: Yup.boolean(),

    billingInfo: Yup.object().when('companyCheckout', {
      is: true,
      then: () =>
        Yup.object({
          email: Yup.string()
            .required(data.error_field_required_message)
            .email('Måste vara en giltig epostaddress'),
          phone: Yup.string().required(data.error_field_required_message),
          ...addressFields,
          orgNr: Yup.string().required(data.error_field_required_message),
          companyName: Yup.string().required(data.error_field_required_message),
        }),
      otherwise: () =>
        Yup.object({
          email: Yup.string()
            .required(data.error_field_required_message)
            .email('Måste vara en giltig epostaddress'),
          phone: Yup.string().required(data.error_field_required_message),
          ...addressFields,
        }),
    }),

    deliveryInfo: Yup.object().when(
      ['useSameAddress', 'companyCheckout'],
      ([useSameAddress, companyCheckout], schema) => {
        if (!useSameAddress && companyCheckout) {
          return Yup.object({
            email: Yup.string().required(data.error_field_required_message),
            phone: Yup.string().required(data.error_field_required_message),
            ...addressFields,
            orgNr: Yup.string(),
            companyName: Yup.string().required(
              data.error_field_required_message
            ),
          });
        } else if (!useSameAddress && !companyCheckout) {
          return Yup.object({
            ...addressFields,
          });
        }
        return schema;
      }
    ),
  });
}

export interface AddressFieldsProps {
  companyCheckout: boolean;
  data: StrapiCheckoutSectionData;
}

export interface AddressValues {
  firstName: string;
  lastName: string;
  address: string;
  address2: string;
  postNr: string;
  city: string;
  country: string;

  companyName: string;
  orgNr: string;

  email: string;
  phone: string;
}

export interface FormValues {
  paymentOption: string;
  useSameAddress: boolean;
  companyCheckout: boolean;
  termsAgreed: boolean;

  deliveryInfo: AddressValues;
  billingInfo: AddressValues;

  invoiceReference: string;
  emailForDigitalInvoice: string;
  comment: string;
}

const checkoutFormValuesPersistenceKey = 'CHECKOUT_FORM_VALUES';
const checkoutFormLoggedInKey = 'CHECKOUT_FORM_LOGGED_IN';
function persistFormValuesToLocalStorage(currentValues: FormValues) {
  typeof sessionStorage !== 'undefined' &&
    sessionStorage.setItem(
      checkoutFormValuesPersistenceKey,
      JSON.stringify(currentValues)
    );
}

function getInitialValues(
  userInfo: User | undefined,
  selectedCustomer: Customer | undefined
): FormValues {
  let sessionStorageString: string | null = null;
  let localStorageUserKey: string | null = null;
  const loggedInUserKey = `${userInfo?.email ?? ''}-${
    selectedCustomer?.id ?? ''
  }`;

  if (typeof sessionStorage !== 'undefined') {
    sessionStorageString = sessionStorage.getItem(
      checkoutFormValuesPersistenceKey
    );
    localStorageUserKey = sessionStorage.getItem(checkoutFormLoggedInKey);
  }

  if (sessionStorageString && localStorageUserKey?.includes(loggedInUserKey)) {
    return JSON.parse(sessionStorageString) as FormValues;
  }

  const addressValues: AddressValues = {
    firstName: userInfo?.first_name ?? '',
    lastName: userInfo?.last_name ?? '',
    address: selectedCustomer?.address ?? '',
    address2: selectedCustomer?.address2 ?? '',
    postNr: selectedCustomer?.zip_code ?? '',
    city: selectedCustomer?.city ?? '',
    country: selectedCustomer?.country ?? 'SE',

    companyName: selectedCustomer?.company_name ?? '',
    orgNr: selectedCustomer?.organization_number ?? '',

    email: userInfo?.email ?? '',
    phone: userInfo?.phone_number ?? '',
  };

  const values = {
    paymentOption: '',
    shippingAlternativeID: '',
    useSameAddress: true,
    companyCheckout: selectedCustomer?.customer_type !== 'private',
    termsAgreed: false,

    deliveryInfo: addressValues,
    billingInfo: addressValues,

    invoiceReference: '',
    emailForDigitalInvoice: '',
    comment: '',
  };

  typeof sessionStorage !== 'undefined' &&
    sessionStorage.setItem(checkoutFormLoggedInKey, loggedInUserKey);
  persistFormValuesToLocalStorage(values);
  return values;
}
