import { strapiGetCardPrice } from '@/utils/heartstarterUtils';
import { WebshopProduct } from '@/modules/apiTypes';
import { StrapiMountingOptionData } from '@/modules/strapiTypes';
import { Dispatch } from 'react';
import { StrapiRichText } from '@/strapiComponents/StrapiRichText';
import { MountingOptionCard } from './MountingOptionCard';
import { useShoppingCart } from '@/context/shoppingCartContext';
import ScrollCarousel from '@/components/general/ScrollCarousel';
import { MountingOptionAmount } from '@/strapiSections/RentHeartstarterSection/RentHeartstarterSection';
import { PageWidth, PageWidthEnum } from '@/utils/strapiUtils';

interface MountingOptionsProps {
  title: string | undefined;
  description: unknown;
  selectButtonLabel: string | undefined;
  selectedButtonLabel: string | undefined;
  mountingOptionGuideText: string | undefined;
  mountingOptions: StrapiMountingOptionData[] | undefined;
  mountingOptionAmount: MountingOptionAmount | undefined;
  setMountingOptionAmount: Dispatch<MountingOptionAmount | undefined>;
  displayMode: 'rent' | 'buy';
  webshopProductMap: Map<string, WebshopProduct>;
  allMountingOptions: StrapiMountingOptionData[];
  pageWidth?: PageWidthEnum;
}

export function MountingOptionSelection({
  title,
  description,
  selectButtonLabel,
  selectedButtonLabel,
  mountingOptionGuideText,
  mountingOptions,
  mountingOptionAmount,
  setMountingOptionAmount,
  displayMode,
  webshopProductMap,
  allMountingOptions,
  pageWidth,
}: MountingOptionsProps) {
  const { displayVat } = useShoppingCart();
  const displayedMountingOptions = mountingOptions
    ? mountingOptions
    : allMountingOptions;
  return (
    <div id="mountingOptionSelection" className="relative pt-12">
      {!mountingOptions && (
        <div className="absolute left-1/2 top-1/2 z-10 max-w-2xl -translate-x-1/2 -translate-y-1/2 font-extralight md:text-32px">
          {mountingOptionGuideText}
        </div>
      )}
      <div
        className="mx-auto max-w-page-width-mx-80 px-default-sm md:px-default"
        style={{ maxWidth: pageWidth && PageWidth[pageWidth] }}
      >
        <h3>{title}</h3>
        <StrapiRichText
          additionalClassNames="pt-1 text-sm font-light md:text-base"
          richText={description}
        />
      </div>
      <div
        className={`mx-auto max-w-page-width-mx-80 ${
          mountingOptions ? '' : 'pointer-events-none blur-lg'
        }`}
      >
        <ScrollCarousel gap={40} pt={16} pb={16}>
          {displayedMountingOptions
            .sort((a, b) => (a?.id ?? 0) - (b?.id ?? 0))
            .map((mountingOption) => {
              const productId =
                mountingOption.attributes?.product?.data?.attributes
                  ?.product_id;
              const product = webshopProductMap.get(productId ?? '');
              return (
                <MountingOptionCard
                  key={mountingOption.id}
                  mountingOption={mountingOption}
                  mountingOptionAmount={mountingOptionAmount}
                  setMountingOptionAmount={setMountingOptionAmount}
                  selectButtonLabel={selectButtonLabel}
                  selectedButtonLabel={selectedButtonLabel}
                  price={strapiGetCardPrice(
                    displayMode,
                    mountingOption,
                    product,
                    displayVat
                  )}
                />
              );
            })}
        </ScrollCarousel>
      </div>
    </div>
  );
}
