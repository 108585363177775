import { Dispatch, ReactNode, useMemo } from 'react';
import { StrapiHeartstarterData } from '@/modules/strapiTypes';
import { StrapiImage } from '@/strapiComponents/StrapiImage';
import { StrapiRichText } from '@/strapiComponents/StrapiRichText';
import { scrollToComponent } from '@/utils/generalUtils';
import {
  HeartstarterAmount,
  MountingOptionAmount,
} from '@/strapiSections/RentHeartstarterSection/RentHeartstarterSection';

interface HeartstarterCardProps {
  heartstarter: StrapiHeartstarterData;
  heartstarterAmount: HeartstarterAmount | undefined;
  setHeartstarterAmount: Dispatch<HeartstarterAmount | undefined>;
  setMountingOptionAmount: Dispatch<MountingOptionAmount | undefined>;
  selectButtonLabel: string | undefined;
  selectedButtonLabel: string | undefined;
  setSelectedHeartstarterDetails: Dispatch<StrapiHeartstarterData | undefined>;
  price?: ReactNode;
}

export function HeartstarterCard({
  heartstarter,
  heartstarterAmount,
  setHeartstarterAmount,
  setMountingOptionAmount,
  selectButtonLabel,
  selectedButtonLabel,
  setSelectedHeartstarterDetails,
  price,
}: HeartstarterCardProps) {
  const firstImage = useMemo(
    () =>
      heartstarter?.attributes?.images?.data &&
      heartstarter?.attributes?.images?.data[0],
    [heartstarter?.attributes?.images?.data]
  );
  const isSelectedHeartstarter = useMemo(
    () => heartstarter.id == heartstarterAmount?.heartstarter?.id,
    [heartstarter.id, heartstarterAmount?.heartstarter?.id]
  );

  return (
    <div className="flex w-72 shrink-0 flex-col justify-between md:w-96">
      <div
        onClick={() => setSelectedHeartstarterDetails(heartstarter)}
        className="cursor-pointer transition-opacity hover:opacity-60"
      >
        {firstImage && (
          <StrapiImage
            className="h-36 w-72 rounded-lg bg-white object-contain p-2 brightness-95 md:h-52 md:w-96"
            strapiImage={{ data: { ...firstImage } }}
          />
        )}
        <p className="custom-item-category mt-4 text-xs font-bold uppercase tracking-hlr-category">
          {heartstarter.attributes?.category}
        </p>
        <p className="custom-item-name">{heartstarter.attributes?.name}</p>
        <StrapiRichText
          additionalClassNames="my-2 text-sm md:text-base"
          richText={heartstarter.attributes?.description_short}
        />
      </div>

      <div className="mt-4 flex items-center justify-between">
        <div className="text-lg font-bold">{price}</div>
        <div
          className="cursor-pointer text-xs font-semibold text-white transition-opacity hover:opacity-60"
          onClick={() => {
            setHeartstarterAmount(
              isSelectedHeartstarter
                ? undefined
                : { heartstarter: heartstarter, amount: 1 }
            );
            setMountingOptionAmount(undefined);
            !isSelectedHeartstarter &&
              scrollToComponent('mountingOptionSelection', 150);
          }}
        >
          <p
            className={`custom-item-button-text rounded-full px-6 py-1.5 ${
              isSelectedHeartstarter ? 'bg-custom-green' : 'bg-hlr-gunmetal'
            }`}
          >
            {isSelectedHeartstarter ? selectedButtonLabel : selectButtonLabel}
          </p>
        </div>
      </div>
    </div>
  );
}
