'use client';
import {
  StrapiHeartstarterData,
  StrapiHeartstarterFeatureData,
  StrapiHeartstarterLocationData,
  StrapiHeartstarterSectionData,
  StrapiMountingOptionData,
} from '@/modules/strapiTypes';
import { StrapiRichText } from '@/strapiComponents/StrapiRichText';
import { Dispatch, useEffect, useMemo, useState } from 'react';
import { LocationSelection } from './LocationSelection';
import { FeatureSelection } from './FeatureSelection';
import { HeartstarterSelection } from './HeartstarterSelection';
import { useUserContext } from '@/context/UserContext';
import { Customer, WebshopProduct } from '@/modules/apiTypes';
import { getProducts } from '@/modules/productMapCache';
import { StrapiImage } from '@/strapiComponents/StrapiImage';
import { HeartstarterDetails } from './HeartstarterDetails';
import { MountingOptionSelection } from './MountingOptionSelection';
import {
  HeartstarterAmount,
  MountingOptionAmount,
} from '@/strapiSections/RentHeartstarterSection/RentHeartstarterSection';
import { PageWidth, PageWidthEnum } from '@/utils/strapiUtils';

interface HeartstarterSectionProps {
  data: StrapiHeartstarterSectionData;
  displayMode: 'rent' | 'buy';
  heartstarterAmount: HeartstarterAmount | undefined;
  setHeartstarterAmount: Dispatch<HeartstarterAmount | undefined>;
  mountingOptionAmount: MountingOptionAmount | undefined;
  setMountingOptionAmount: Dispatch<MountingOptionAmount | undefined>;
  pageWidth?: PageWidthEnum;
}

export function HeartstarterSection({
  data,
  displayMode,
  heartstarterAmount,
  setHeartstarterAmount,
  mountingOptionAmount,
  setMountingOptionAmount,
  pageWidth,
}: HeartstarterSectionProps) {
  const { selectedCustomer, authToken } = useUserContext();

  const allHeartstarters: StrapiHeartstarterData[] = useMemo(
    () => (data.heartstarters?.data as StrapiHeartstarterData[]) ?? [],
    [data.heartstarters?.data]
  );
  const allLocations: StrapiHeartstarterLocationData[] = useMemo(
    () => getAllLocations(allHeartstarters),
    [allHeartstarters]
  );
  const allFeatures: StrapiHeartstarterFeatureData[] = useMemo(
    () => getAllFeatures(allHeartstarters),
    [allHeartstarters]
  );
  const [webshopProductMap, setWebshopProductMap] = useState<
    Map<string, WebshopProduct>
  >(new Map());
  useEffect(() => {
    getWebshopProductMap(
      allHeartstarters,
      setWebshopProductMap,
      selectedCustomer,
      authToken
    );
  }, [authToken, allHeartstarters, selectedCustomer]);

  const [selectedLocation, setSelectedLocation] = useState<
    number | undefined
  >();
  const [selectedFeatures, setSelectedFeatures] = useState<number[]>([]);
  const [selectedHeartstarterDetails, setSelectedHeartstarterDetails] =
    useState<StrapiHeartstarterData | undefined>();

  const [filteredHeartstarters, setFilteredHeartstarters] = useState<
    StrapiHeartstarterData[]
  >([]);
  useEffect(() => {
    setFilteredHeartstarters(
      updateFilteredHeartstarters(
        allHeartstarters,
        selectedLocation,
        selectedFeatures
      )
    );
  }, [allHeartstarters, selectedFeatures, selectedLocation]);
  const availableFeatures: number[] = useMemo(
    () =>
      getAllFeatures(filteredHeartstarters).map((feature) => feature.id ?? 0),
    [filteredHeartstarters]
  );

  return (
    <>
      {selectedHeartstarterDetails && (
        <HeartstarterDetails
          heartstarter={selectedHeartstarterDetails}
          selectButtonLabel={data.choose_text}
          setSelectedHeartstarterDetails={setSelectedHeartstarterDetails}
          setHeartstarterAmount={setHeartstarterAmount}
          displayMode={displayMode}
          webshopProductMap={webshopProductMap}
        />
      )}
      <div className="pt-28" style={{ backgroundColor: data.background_color }}>
        <div
          className="mx-auto max-w-page-width-mx-80 pb-8"
          style={{ maxWidth: pageWidth && PageWidth[pageWidth] }}
        >
          <div className="px-default-sm md:px-default">
            <p className="text-pre-header">{data.title}</p>
            <StrapiRichText richText={data.header} />
            <StrapiRichText
              additionalClassNames="text-body-hero mt-4 max-w-4xl pb-10"
              richText={data.description}
            />
            <LocationSelection
              header={data.location_header}
              description={data.location_description}
              locations={allLocations}
              selectedLocation={selectedLocation}
              setSelectedLocation={setSelectedLocation}
              setSelectedFeatures={setSelectedFeatures}
              setHeartstarterAmount={setHeartstarterAmount}
              setMountingOptionAmount={setMountingOptionAmount}
            />
          </div>
          <FeatureSelection
            header={data.feature_header}
            description={data.feature_description}
            features={allFeatures}
            selectedFeatures={selectedFeatures}
            setSelectedFeatures={setSelectedFeatures}
            availableFeatures={availableFeatures}
            selectedLocation={selectedLocation}
          />
        </div>
      </div>
      <div>
        {selectedLocation ? (
          <div className="py-8 md:py-12">
            <HeartstarterSelection
              title={data.hs_header}
              description={data.hs_description}
              storeLink={data.store_link}
              selectButtonLabel={data.choose_text}
              selectedButtonLabel={data.chosen_text}
              heartstarters={filteredHeartstarters}
              heartstarterAmount={heartstarterAmount}
              setHeartstarterAmount={setHeartstarterAmount}
              setSelectedHeartstarterDetails={setSelectedHeartstarterDetails}
              setMountingOptionAmount={setMountingOptionAmount}
              displayMode={displayMode}
              webshopProductMap={webshopProductMap}
              pageWidth={pageWidth}
            />
            <MountingOptionSelection
              title={data.mo_header}
              description={data.mo_description}
              selectButtonLabel={data.choose_text}
              selectedButtonLabel={data.chosen_text}
              mountingOptionGuideText={data.mo_guide_text}
              mountingOptions={
                heartstarterAmount?.heartstarter?.attributes?.mounting_options
                  ?.data as StrapiMountingOptionData[]
              }
              mountingOptionAmount={mountingOptionAmount}
              setMountingOptionAmount={setMountingOptionAmount}
              displayMode={displayMode}
              webshopProductMap={webshopProductMap}
              allMountingOptions={getAllMountingOptions(filteredHeartstarters)}
              pageWidth={pageWidth}
            />
          </div>
        ) : (
          <div className="flex flex-row items-center justify-center gap-5 px-default-sm py-8 md:gap-20 md:px-default md:py-12">
            {data.guide_image && (
              <StrapiImage
                className="h-36 w-36 md:h-72 md:w-72"
                strapiImage={data.guide_image}
              />
            )}
            <div className="max-w-xl font-extralight md:text-32px">
              {data.guide_text}
            </div>
          </div>
        )}
      </div>
    </>
  );
}

function getAllLocations(
  heartstarters: StrapiHeartstarterData[]
): StrapiHeartstarterLocationData[] {
  const allLocations: StrapiHeartstarterLocationData[] = heartstarters.flatMap(
    (heartstarter) =>
      (heartstarter.attributes?.hs_locations
        ?.data as StrapiHeartstarterLocationData) ?? []
  );
  const uniqueLocationsMap = new Map<number, StrapiHeartstarterLocationData>();
  allLocations.forEach((location) => {
    uniqueLocationsMap.set(location?.id ?? 0, location);
  });
  return Array.from(uniqueLocationsMap.values()).sort(
    (a, b) => (a?.id ?? 0) - (b?.id ?? 0)
  );
}

function getAllFeatures(
  heartstarters: StrapiHeartstarterData[]
): StrapiHeartstarterFeatureData[] {
  const allFeatures: StrapiHeartstarterFeatureData[] = heartstarters.flatMap(
    (heartstarter) =>
      (heartstarter.attributes?.hs_features
        ?.data as StrapiHeartstarterFeatureData) ?? []
  );
  const uniqueFeaturesMap = new Map<number, StrapiHeartstarterFeatureData>();
  allFeatures.forEach((feature) => {
    uniqueFeaturesMap.set(feature?.id ?? 0, feature);
  });
  return Array.from(uniqueFeaturesMap.values()).sort(
    (a, b) => (a?.id ?? 0) - (b?.id ?? 0)
  );
}

function getAllMountingOptions(
  heartstarters: StrapiHeartstarterData[]
): StrapiMountingOptionData[] {
  const allMountingOptions: StrapiMountingOptionData[] = heartstarters.flatMap(
    (heartstarter) =>
      (heartstarter.attributes?.mounting_options
        ?.data as StrapiMountingOptionData) ?? []
  );
  const uniqueMountingOptionMap = new Map<number, StrapiMountingOptionData>();
  allMountingOptions.forEach((mountingOption) => {
    uniqueMountingOptionMap.set(mountingOption?.id ?? 0, mountingOption);
  });
  return Array.from(uniqueMountingOptionMap.values());
}

function updateFilteredHeartstarters(
  heartstarterList: StrapiHeartstarterData[],
  selectedLocation: number | undefined,
  selectedFeatures: number[]
): StrapiHeartstarterData[] {
  if (!selectedLocation) {
    return [];
  }
  const locationFilteredHeartstarters = heartstarterList.filter(
    (heartstarter) =>
      heartstarter.attributes?.hs_locations?.data
        ?.flatMap((location) => location.id)
        .includes(selectedLocation)
  );

  const locationFeaturesFilteredHeartstarters =
    selectedFeatures.length > 0
      ? locationFilteredHeartstarters.filter((heartstarter) =>
          selectedFeatures.every((feature) =>
            getAllFeatures([heartstarter])
              .map((f) => f.id)
              .includes(feature)
          )
        )
      : locationFilteredHeartstarters;

  return locationFeaturesFilteredHeartstarters;
}

function getWebshopProductMap(
  heartstarters: StrapiHeartstarterData[],
  setWebshopProductMap: Dispatch<Map<string, WebshopProduct>>,
  selectedCustomer?: Customer,
  authToken?: string
) {
  const fetch = async () => {
    const ids = new Set(
      heartstarters.map(
        (heartstarter) =>
          heartstarter.attributes?.product?.data?.attributes?.product_id ?? ''
      )
    );
    for (const item of heartstarters) {
      item.attributes?.mounting_options?.data?.map((mountingOptions) =>
        ids.add(
          mountingOptions.attributes?.product?.data?.attributes?.product_id ??
            ''
        )
      );
    }
    const prods = await getProducts(
      Array.from(ids),
      selectedCustomer,
      authToken
    );
    setWebshopProductMap(new Map(prods.map((p) => [p.id.toString(), p])));
  };
  void fetch();
}
