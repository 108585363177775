import { StrapiHeartstarterFeatureData } from '@/modules/strapiTypes';
import { StrapiImage } from '@/strapiComponents/StrapiImage';
import { StrapiRichText } from '@/strapiComponents/StrapiRichText';
import { Dispatch } from 'react';

interface FeatureSelectionProps {
  header: string | undefined;
  description: unknown;
  features: StrapiHeartstarterFeatureData[];
  selectedFeatures: number[];
  setSelectedFeatures: Dispatch<number[]>;
  availableFeatures: number[];
  selectedLocation: number | undefined;
}

export function FeatureSelection({
  header,
  description,
  features,
  selectedFeatures,
  setSelectedFeatures,
  availableFeatures,
  selectedLocation,
}: FeatureSelectionProps) {
  const toggleFeature = (feature: number) => {
    if (selectedFeatures.includes(feature)) {
      setSelectedFeatures(selectedFeatures.filter((f) => f !== feature));
    } else {
      setSelectedFeatures([...selectedFeatures, feature]);
    }
  };

  return (
    <div
      id="featureSelection"
      className={selectedLocation === undefined ? 'opacity-50' : ''}
    >
      <div className="px-default-sm md:px-default">
        <h3>{header}</h3>
        <StrapiRichText
          additionalClassNames="pt-1 text-sm font-light md:text-base"
          richText={description}
        />
      </div>
      <div className="grid grid-cols-2 gap-4 px-default-sm py-4 md:flex md:flex-row md:flex-wrap md:px-default">
        {features?.map((feature) => (
          <div
            key={feature?.id}
            className={`flex w-full max-w-xs shrink-0 border-collapse flex-col rounded-lg border bg-white p-3 transition-opacity md:w-60 ${
              selectedFeatures.includes(feature.id ?? 0)
                ? ' border-2 border-blue-500 shadow-lg'
                : ' border-custom-darkgrey '
            }
              ${
                availableFeatures?.includes(feature.id ?? 0)
                  ? ' cursor-pointer hover:opacity-60'
                  : ' cursor-not-allowed opacity-25 hover:opacity-25'
              }
              `}
            onClick={() => {
              availableFeatures?.includes(feature.id ?? 0) &&
                toggleFeature(feature.id ?? 0);
            }}
          >
            <div className="flex flex-row items-center gap-2">
              {feature.attributes?.image && (
                <StrapiImage
                  className="h-5 w-5 shrink-0"
                  strapiImage={feature.attributes?.image}
                />
              )}
              <div className="text-sm font-bold md:truncate">
                {feature?.attributes?.title}
              </div>
            </div>
            <div className="hidden pt-1 text-sm leading-tight md:flex">
              {feature?.attributes?.description}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
