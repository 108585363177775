import { StrapiFormSectionData } from '@/modules/strapiTypes';
import { PropsWithChildren } from 'react';
import { BaseContactInformation } from './BaseContactInformation';
import { PageWidth, PageWidthEnum } from '@/utils/strapiUtils';

export function BaseContactForm({
  data,
  backgroundColor,
  children,
  pageWidth,
  id,
}: PropsWithChildren<{
  data: StrapiFormSectionData;
  backgroundColor: string | undefined;
  pageWidth?: PageWidthEnum;
  id: string | undefined;
}>) {
  return (
    <div
      id={id}
      style={{ backgroundColor: backgroundColor }}
      className="px-default-sm md:px-default"
    >
      <div
        className="mx-auto grid max-w-page-width-mx-560 grid-cols-1 md:grid-cols-2 md:py-10"
        style={{ maxWidth: pageWidth && PageWidth[pageWidth] }}
      >
        <div className="w-full max-w-2xl pt-8 md:py-8">
          <BaseContactInformation {...data} />
        </div>
        <div className="w-full py-8">{children}</div>
      </div>
    </div>
  );
}
