import { ReactNode } from 'react';
import { WebshopProduct } from '../modules/apiTypes';
import { ProductPrice } from '../components/pageStore/ProductPrice';
import {
  StrapiHeartstarterData,
  StrapiMountingOptionData,
} from '@/modules/strapiTypes';

export function strapiGetCardPrice(
  displayMode: 'rent' | 'buy' | undefined,
  data: StrapiHeartstarterData | StrapiMountingOptionData,
  product?: WebshopProduct,
  displayVat?: boolean
): ReactNode {
  if (displayMode === 'rent') {
    return <div>{data.attributes?.rent_price}</div>;
  }

  return <ProductPrice displayVat={displayVat} product={product} />;
}
