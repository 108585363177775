'use client';
import Button from '@/components/general/Button';
import {
  TextArea,
  TextField,
  TextSelect,
} from '@/components/general/TextFields';
import { sendInstructorClassRequest } from '@/modules/apiClient';
import { Formik } from 'formik';
import { useMemo, useState } from 'react';
import * as Yup from 'yup';
import { CancellationPaymentAgreementModal } from './CancellationPaymentAgreementModal';
import { sendAnalyticsEvent } from '@/modules/googleAnalytics';
import {
  StrapiClassDateLocation,
  StrapiClassFormData,
  StrapiClassFormSectionData,
  StrapiFormSectionData,
} from '@/modules/strapiTypes';
import { BaseContactForm } from '@/strapiComponents/BaseContactForm';
import { SubmitStatusSuccess } from '@/strapiComponents/SubmitStatusSuccess';

function getFormSchema(fieldRequiredErrorMessage: string) {
  return Yup.object({
    courseLocation: Yup.string().required(fieldRequiredErrorMessage),
    courseDate: Yup.string().required(fieldRequiredErrorMessage),
    firstName: Yup.string().required(fieldRequiredErrorMessage),
    lastName: Yup.string().required(fieldRequiredErrorMessage),
    email: Yup.string().required(fieldRequiredErrorMessage),
    phoneNumber: Yup.string().required(fieldRequiredErrorMessage),
    invoiceCompany: Yup.string(),
    invoiceSection: Yup.string(),
    invoiceAddress: Yup.string().required(fieldRequiredErrorMessage),
    invoicePostalCode: Yup.string().required(fieldRequiredErrorMessage),
    invoiceCity: Yup.string().required(fieldRequiredErrorMessage),
    organisationOrPersonalNumber: Yup.string().required(
      fieldRequiredErrorMessage
    ),
    referenceNumber: Yup.string(),
    invoiceEmail: Yup.string(),
    deliveryName: Yup.string(),
    deliveryCompany: Yup.string(),
    deliveryAddress: Yup.string(),
    deliveryPostalCode: Yup.string(),
    deliveryCity: Yup.string(),
    message: Yup.string(),
  });
}

export interface InstructorClassFormValues {
  courseLocation: string;
  courseDate: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  invoiceCompany: string;
  invoiceSection: string;
  invoiceAddress: string;
  invoicePostalCode: string;
  invoiceCity: string;
  organisationOrPersonalNumber: string;
  referenceNumber: string;
  invoiceEmail: string;
  deliveryName: string;
  deliveryCompany: string;
  deliveryAddress: string;
  deliveryPostalCode: string;
  deliveryCity: string;
  message: string;
}

const instructorClassFormInitialValues: InstructorClassFormValues = {
  courseLocation: '',
  courseDate: '',
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  invoiceCompany: '',
  invoiceSection: '',
  invoiceAddress: '',
  invoicePostalCode: '',
  invoiceCity: '',
  organisationOrPersonalNumber: '',
  referenceNumber: '',
  invoiceEmail: '',
  deliveryName: '',
  deliveryCompany: '',
  deliveryAddress: '',
  deliveryPostalCode: '',
  deliveryCity: '',
  message: '',
};

interface InstructorClassFormProps {
  classPrice?: number;
}

export function InstructorClassForm({
  classPrice,
  ...props
}: StrapiClassFormSectionData & InstructorClassFormProps) {
  const [submitStatus, setSubmitStatus] = useState<'success' | 'failed'>();
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [
    displayCancellationPaymentAgreementModal,
    setDisplayCancellationPaymentAgreementModal,
  ] = useState<boolean>();

  const formProps = props.class_form?.data?.attributes as
    | StrapiClassFormData
    | undefined;

  const validationSchema = useMemo(
    () =>
      getFormSchema(formProps?.field_is_required_error_text ?? 'Obligatoriskt'),
    [formProps]
  );

  if (!formProps) {
    return undefined;
  }

  const classDateLocationMap = parseClassDateLocationList(
    props.date_locations ?? []
  );

  const handleSubmit = async (
    values: InstructorClassFormValues
  ): Promise<void> => {
    setSubmitStatus(undefined);
    try {
      await sendInstructorClassRequest({
        ...values,
        url: window.location.href,
        requestType: 'contact',
      });
      setSubmitStatus('success');
      sendAnalyticsEvent({
        name:
          props.class_type === 'instructor'
            ? 'bokning_oppen_instruktor'
            : 'bokning_oppen_utbildning',
        value: classPrice ?? 0,
      });
    } catch {
      setSubmitStatus('failed');
    }
  };

  const handleCourseLocationChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
    setFieldValue: (field: string, value: string) => void
  ) => {
    const newLocation = event.target.value;

    setFieldValue('courseLocation', newLocation);
    setFieldValue('courseDate', '');
  };

  const showIntrestCheckbox = (values: InstructorClassFormValues) => {
    return (
      values.courseLocation &&
      (classDateLocationMap.get(values.courseLocation)?.length ?? 0) === 0
    );
  };

  return (
    <>
      <BaseContactForm
        data={props.class_form?.data as StrapiFormSectionData}
        backgroundColor={props.background_color}
        pageWidth={props.page_width}
        id={props.anchor}
      >
        <>
          {submitStatus === 'success' && (
            <div className="flex justify-end">
              <SubmitStatusSuccess
                successHeader={formProps.success_header ?? ''}
                successDescription={formProps.success_text}
                resetSubmitSuccessful={() => setSubmitStatus(undefined)}
              />
            </div>
          )}
          {submitStatus !== 'success' && (
            <Formik
              validationSchema={validationSchema}
              initialValues={instructorClassFormInitialValues}
              validateOnBlur={true}
              onSubmit={handleSubmit}
            >
              {({ submitForm, isSubmitting, values, setFieldValue }) => (
                <>
                  <div className="flex flex-col gap-2 md:flex-row">
                    <TextSelect
                      name="courseLocation"
                      label={formProps.location_label}
                      defaultSelectLabel={formProps.location_placeholder}
                      isRequired={true}
                      selectList={Array.from(classDateLocationMap.keys())}
                      onChange={(event) =>
                        handleCourseLocationChange(event, setFieldValue)
                      }
                    />
                    <TextSelect
                      name="courseDate"
                      label={formProps.date_label}
                      defaultSelectLabel={formProps.date_placeholder}
                      isRequired={true}
                      selectList={classDateLocationMap.get(
                        values.courseLocation
                      )}
                      disabled={values.courseLocation === ''}
                    />
                  </div>
                  {showIntrestCheckbox(values) && (
                    <div className="mb-2 flex items-center gap-2">
                      <input
                        type="checkbox"
                        className="form-checkbox h-6 w-6"
                        name="interestApplication"
                        checked={values.courseLocation === 'Intresseanmälan'}
                        onChange={(event) => {
                          const isChecked = event.target.checked;
                          const newCourseDate = isChecked
                            ? 'Intresseanmälan'
                            : '';
                          setFieldValue('courseDate', newCourseDate);
                        }}
                      />
                      <p className="md:text-lg">Intresseanmälan</p>
                    </div>
                  )}
                  <div className="flex flex-col gap-2 md:flex-row">
                    <TextField
                      name="firstName"
                      label={formProps.first_name_label}
                      isRequired={true}
                      autoComplete="given-name"
                    />
                    <TextField
                      name="lastName"
                      label={formProps.last_name_label}
                      isRequired={true}
                      autoComplete="family-name"
                    />
                  </div>
                  <TextField
                    name="phoneNumber"
                    label={formProps.phone_number_label}
                    isRequired={true}
                    autoComplete="tel"
                  />
                  <TextField
                    name="email"
                    label={formProps.email_label}
                    isRequired={true}
                    autoComplete="email"
                  />

                  <p className="my-2 text-lg font-bold">
                    {formProps.invoice_information_subheader}
                  </p>

                  <div className="flex flex-col gap-2 md:flex-row">
                    <TextField
                      name="invoiceCompany"
                      label={formProps.company_label}
                      autoComplete="organization"
                    />
                    <TextField name="invoiceSection" label={'Avdelning'} />
                  </div>
                  <div className="flex flex-col gap-2 md:flex-row">
                    <TextField
                      name="invoiceAddress"
                      label={formProps.address_label}
                      isRequired={true}
                      autoComplete="address-line1"
                    />
                    <TextField
                      name="invoicePostalCode"
                      label={formProps.zip_code_label}
                      isRequired={true}
                      autoComplete="postal-code"
                    />
                  </div>
                  <div className="flex flex-col gap-2 md:flex-row">
                    <TextField
                      name="invoiceCity"
                      label={formProps.city_label}
                      isRequired={true}
                    />
                    <TextField
                      name="organisationOrPersonalNumber"
                      label={formProps.organization_number_label}
                      isRequired={true}
                    />
                  </div>
                  <div className="flex flex-col gap-2 md:flex-row">
                    <TextField
                      name="referenceNumber"
                      label={formProps.invoice_reference_label}
                    />
                    <TextField
                      name="invoiceEmail"
                      label={formProps.invoice_email_label}
                      autoComplete="email"
                    />
                  </div>

                  <p className="my-2 text-lg font-bold">
                    {formProps.delivery_information_subheader}
                  </p>

                  <div className="flex flex-col gap-2 md:flex-row">
                    <TextField
                      name="deliveryName"
                      label={formProps.delivery_name_label}
                      autoComplete="given-name"
                    />
                    <TextField
                      name="deliveryCompany"
                      label={formProps.delivery_company_label}
                      autoComplete="organization"
                    />
                  </div>
                  <div className="flex flex-col gap-2 md:flex-row">
                    <TextField
                      name="deliveryAddress"
                      label={formProps.delivery_address_label}
                      autoComplete="address-line1"
                    />
                    <TextField
                      name="deliveryPostalCode"
                      label={formProps.delivery_zip_code_label}
                      autoComplete="postal-code"
                    />
                  </div>
                  <TextField
                    name="deliveryCity"
                    label={formProps.delivery_city_label}
                  />
                  <div className="mt-6">
                    <TextArea name="message" label={formProps.message_label} />
                  </div>
                  <div className="mt-2 flex items-center justify-between gap-2">
                    <div className="flex items-center gap-2">
                      <input
                        className="form-checkbox h-6 w-6"
                        type="checkbox"
                        id="ToS-checkbox"
                        checked={termsAgreed}
                        onChange={() => setTermsAgreed(!termsAgreed)}
                      />
                      <p
                        className="cursor-pointer text-sm font-bold hover:underline"
                        onClick={() =>
                          setDisplayCancellationPaymentAgreementModal(true)
                        }
                      >
                        {formProps.tos_label}
                      </p>
                    </div>
                    <Button
                      variantColor={isSubmitting ? 'grey' : 'green'}
                      label={formProps.send_button_label}
                      onClick={() => {
                        if (!isSubmitting) {
                          void submitForm();
                        }
                      }}
                      additionalClassNames={
                        !termsAgreed ? 'opacity-60 pointer-events-none' : ''
                      }
                      disabled={!termsAgreed || isSubmitting}
                    />
                    {submitStatus === 'failed' && (
                      <span className="text-red-500">
                        {formProps.fail_text}
                      </span>
                    )}
                  </div>
                </>
              )}
            </Formik>
          )}
        </>
      </BaseContactForm>
      {displayCancellationPaymentAgreementModal && (
        <CancellationPaymentAgreementModal
          text={formProps.tos_text}
          setDisplayCancellationPaymentAgreementModal={
            setDisplayCancellationPaymentAgreementModal
          }
        />
      )}
    </>
  );
}

function parseClassDateLocationList(
  list: StrapiClassDateLocation[]
): Map<string, string[]> {
  return list.reduce((map, { location, time }) => {
    if (!!location && !!time) {
      if (!map.has(location)) {
        map.set(location, []);
      }
      map.get(location)?.push(time);
    }
    return map;
  }, new Map<string, string[]>());
}
