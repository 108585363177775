import { StrapiCheckoutSectionData } from '@/modules/strapiTypes';
import { Dispatch } from 'react';

interface OrderCreationFailureModalProps {
  data: StrapiCheckoutSectionData;
  setDisplayOrderCreationFailureModal: Dispatch<boolean>;
}

export default function OrderCreationFailureModal({
  data,
  setDisplayOrderCreationFailureModal,
}: OrderCreationFailureModalProps) {
  return (
    <div
      className="fixed inset-0 z-50 bg-custom-darkgrey/50 p-default-sm md:px-default"
      onClick={() => setDisplayOrderCreationFailureModal(false)}
    >
      <div
        className="mx-auto mt-16vh h-auto max-w-page-width-mx-680 bg-white p-8"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="mb-2 flex items-center justify-between gap-2">
          <h3>{data.fields_order_creation_failed_header}</h3>
          <button
            onClick={() => setDisplayOrderCreationFailureModal(false)}
            className="text-xl md:text-3xl"
          >
            &#x2715;
          </button>
        </div>
        <p className="text-sm md:text-base">
          {data.fields_order_creation_failed_description}
        </p>
      </div>
    </div>
  );
}
