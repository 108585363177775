import Image from 'next/image';
import QuantitySelector from '@/components/general/QuantitySelector';
import {
  ShoppingCartAction,
  useShoppingCart,
  WebshopProductMap,
} from '@/context/shoppingCartContext';
import Link from 'next/link';
import { strapiGetCardPrice } from '@/utils/heartstarterUtils';
import { calculateTotalCost } from '@/utils/cartUtils';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import FormattedPrice from '@/components/general/FormattedPrice';
import TrashIcon from '@/icons/trash.svg';
import { getProducts } from '@/modules/productMapCache';
import { useUserContext } from '@/context/UserContext';
import { ProductAmount } from '@/modules/apiTypes';
import {
  HeartstarterAmount,
  MountingOptionAmount,
} from './BuyHeartstarterSection';
import { StrapiImageData } from '@/modules/strapiTypes';
import { StrapiImage } from '@/strapiComponents/StrapiImage';
import { PageWidth, PageWidthEnum } from '@/utils/strapiUtils';

interface SelectedProductAmountsCartProps {
  productAmountHeader: string | undefined;
  backgroundColor: string | undefined;
  heartstarterAmount: HeartstarterAmount | undefined;
  setHeartstarterAmount(value: HeartstarterAmount | undefined): void;
  mountingOptionAmount: MountingOptionAmount | undefined;
  setMountingOptionAmount(value: MountingOptionAmount | undefined): void;
  pageWidth?: PageWidthEnum;
}

export default function SelectedProductAmountsCart({
  productAmountHeader,
  backgroundColor,
  heartstarterAmount,
  setHeartstarterAmount,
  mountingOptionAmount,
  setMountingOptionAmount,
  pageWidth,
}: SelectedProductAmountsCartProps) {
  const { displayVat, updateShoppingCart } = useShoppingCart();
  const { selectedCustomer, authToken } = useUserContext();
  const [webshopProductMap, setWebshopProductMap] = useState<WebshopProductMap>(
    new Map()
  );

  const heartstarterId = heartstarterAmount?.heartstarter?.attributes?.product
    ?.data?.attributes?.product_id as unknown as string;
  const heartstarterProduct = webshopProductMap.get(heartstarterId);
  const heartstarterProductPrice =
    heartstarterAmount &&
    strapiGetCardPrice(
      'buy',
      heartstarterAmount.heartstarter,
      heartstarterProduct,
      displayVat
    );
  const firstHeartstarterImage = useMemo(
    () =>
      heartstarterAmount?.heartstarter?.attributes?.images?.data &&
      heartstarterAmount?.heartstarter?.attributes?.images?.data[0],
    [heartstarterAmount?.heartstarter?.attributes?.images?.data]
  );

  const mountingOptionId = mountingOptionAmount?.mountingOption.attributes
    ?.product?.data?.attributes?.product_id as unknown as string;
  const mountingOptionProduct = webshopProductMap.get(mountingOptionId);
  const mountingOptionProductPrice =
    mountingOptionAmount &&
    strapiGetCardPrice(
      'buy',
      mountingOptionAmount?.mountingOption,
      mountingOptionProduct,
      displayVat
    );

  useEffect(() => {
    const fetch = async () => {
      const prods = await getProducts(
        [heartstarterId, mountingOptionId],
        selectedCustomer,
        authToken
      );
      setWebshopProductMap(new Map(prods.map((p) => [p.id.toString(), p])));
    };
    void fetch();
  }, [authToken, heartstarterId, mountingOptionId, selectedCustomer]);

  const handleHSAmountChange = (newAmount: number) => {
    if (heartstarterAmount && newAmount >= 1) {
      setHeartstarterAmount({ ...heartstarterAmount, amount: newAmount });
    } else {
      setHeartstarterAmount(undefined);
    }
  };

  const handleMOAmountChange = (newAmount: number) => {
    if (mountingOptionAmount && newAmount >= 1) {
      setMountingOptionAmount({
        ...mountingOptionAmount,
        amount: newAmount,
      });
    } else {
      setMountingOptionAmount(undefined);
    }
  };

  const { totalCost, totalDiscountedCost } = useMemo(() => {
    const cart: ProductAmount[] = [];
    heartstarterProduct &&
      cart.push({
        product: heartstarterProduct,
        amount: heartstarterAmount?.amount ?? 1,
      });
    mountingOptionProduct &&
      cart.push({
        product: mountingOptionProduct,
        amount: mountingOptionAmount?.amount ?? 1,
      });
    return calculateTotalCost(displayVat, cart, undefined);
  }, [
    heartstarterProduct,
    heartstarterAmount?.amount,
    mountingOptionProduct,
    mountingOptionAmount?.amount,
    displayVat,
  ]);

  function handleProceedToCheckout() {
    heartstarterAmount &&
      updateShoppingCart({
        type: ShoppingCartAction.ADD,
        payload: {
          id: heartstarterId,
          amount: heartstarterAmount.amount,
          name: heartstarterProduct?.name,
        },
      });
    mountingOptionAmount &&
      updateShoppingCart({
        type: ShoppingCartAction.ADD,
        payload: {
          id: mountingOptionId,
          amount: mountingOptionAmount.amount,
          name: mountingOptionProduct?.name,
        },
      });
  }

  return (
    <div
      className="py-10"
      id="selectedHeartstarters"
      style={{ backgroundColor: backgroundColor }}
    >
      <div
        className="mx-auto max-w-page-width-mx-80 px-default-sm md:px-default"
        style={{ maxWidth: pageWidth && PageWidth[pageWidth] }}
      >
        <h2 className="mb-2">{productAmountHeader ?? 'Valda produkter'}</h2>
        <div className="my-6 flex flex-col space-y-6 border-b border-hlr-gunmetal py-6 md:space-y-4">
          {heartstarterAmount && (
            <LineItem
              name={heartstarterAmount.heartstarter.attributes?.name}
              image={{ data: firstHeartstarterImage }}
              price={heartstarterProductPrice}
              amount={heartstarterAmount.amount}
              handleAmountChange={handleHSAmountChange}
            />
          )}
          {mountingOptionAmount && (
            <LineItem
              name={mountingOptionAmount.mountingOption.attributes?.name}
              image={mountingOptionAmount.mountingOption.attributes?.image}
              price={mountingOptionProductPrice}
              amount={mountingOptionAmount.amount}
              handleAmountChange={handleMOAmountChange}
            />
          )}
        </div>
        <div className="flex flex-col items-center justify-between gap-2 border-hlr-gunmetal md:flex-row md:gap-8">
          <div className="flex w-full flex-wrap justify-between gap-x-8 gap-y-2">
            <div className="my-auto flex flex-row items-center gap-2 font-raleway md:text-xl">
              <h2 className="text-lg font-light md:text-32px">Totalt:</h2>
              <h2 className="font-mulish text-lg font-bold md:text-32px">
                <FormattedPrice
                  price={totalCost}
                  discountedPrice={totalDiscountedCost}
                />
              </h2>
              <h2 className="text-lg font-light md:text-32px">
                {displayVat ? 'inkl moms' : 'exkl moms'}
              </h2>
            </div>
            <div className="my-auto hidden flex-row items-center gap-2 font-raleway md:flex">
              <div className="text-sm md:text-lg">
                {displayVat ? 'inkl moms' : 'exkl moms' + ':'}
              </div>
              <div className="font-mulish text-sm font-bold md:text-lg">
                <FormattedPrice
                  price={totalCost}
                  discountedPrice={totalDiscountedCost}
                />
              </div>
            </div>
          </div>
          <Link
            href={'/butik/kassa'}
            onClick={() => handleProceedToCheckout()}
            className="self-end"
          >
            <div className="w-max rounded-full bg-hlr-gunmetal px-3 py-1.5 text-center text-sm text-white md:px-4 md:py-3 md:text-lg md:font-semibold">
              Gå till kassan
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}

interface LineItemProps {
  image?: StrapiImageData | undefined;
  name?: string;
  price?: ReactNode;
  amount: number;
  handleAmountChange(n: number): void;
}

function LineItem({
  image,
  name,
  price,
  amount,
  handleAmountChange,
}: LineItemProps) {
  return (
    <div className="flex w-full flex-col justify-between md:flex-row">
      <div className="flex flex-row">
        {image && (
          <div className="h-16 w-24 shrink-0 grow-0 rounded bg-white">
            <StrapiImage
              className="h-16 w-24 shrink-0 grow-0 rounded border object-contain"
              strapiImage={image}
            />
          </div>
        )}
        <p className="h-min px-4 py-2 font-raleway text-sm font-bold md:px-6 md:text-lg">
          {name}
        </p>
      </div>
      <div className="flex justify-end gap-4">
        <div className="my-auto flex flex-row justify-between gap-4 whitespace-nowrap">
          <div className="custom-item-price mt-auto md:my-auto">{price}</div>
          <p className="pointer-events-none self-center text-gray-400">
            &#x2715;
          </p>
          <QuantitySelector
            amount={amount}
            handleIncrementClick={() => handleAmountChange(amount + 1)}
            handleDecrementClick={() => handleAmountChange(amount - 1)}
            handleAmountChange={(n) => handleAmountChange(n)}
          />
        </div>
        <button
          className="flex h-8 w-8 items-center justify-center self-center rounded-full text-lg transition-colors hover:bg-hlr-gunmetal/20 md:text-xl"
          onClick={() => handleAmountChange(0)}
        >
          <Image src={TrashIcon as string} alt="trash" width={24} height={24} />
        </button>
      </div>
    </div>
  );
}
