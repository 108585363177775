import ProductCard from '@/components/general/ProductCard';
import { WebshopProduct } from '@/modules/apiTypes';
import { StrapiLink } from '@/modules/strapiTypes';
import Link from 'next/link';

interface RelatedProductsProps {
  header: string | undefined;
  storeLink: StrapiLink | undefined;
  products: WebshopProduct[];
}

export function RelatedProducts({
  header,
  storeLink,
  products,
}: RelatedProductsProps) {
  return (
    <>
      {products.length > 0 && (
        <div className="mx-auto mb-10 mt-16">
          <div className="flex flex-col justify-between gap-4">
            <div>{header && <h2 className="mb-2">{header}</h2>}</div>
            {storeLink && (
              <Link
                href={storeLink.url ?? ''}
                rel={storeLink?.rel}
                target={storeLink?.open_in_new_tab ? '_blank' : '_self'}
              >
                <h3 className="text-lg hover:underline">
                  {storeLink.display_name} &rarr;
                </h3>
              </Link>
            )}
          </div>
          <div className="flex flex-wrap gap-x-4 gap-y-10 py-10 md:gap-x-8">
            {products.map((product) => (
              <ProductCard
                key={product.name}
                product={product}
                productCategoryName={''}
              />
            ))}
          </div>
        </div>
      )}
    </>
  );
}
