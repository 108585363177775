import Link from 'next/link';
import FormattedPrice from '@/components/general/FormattedPrice';
import { useTotalCostWithShipping } from '@/utils/cartUtils';
import { StrapiCheckoutSectionData } from '@/modules/strapiTypes';
import { FormValues } from './CheckoutForm';
import { ValidatedDiscountCode } from '@/modules/apiTypes';

interface ConfirmCheckoutProps {
  data: StrapiCheckoutSectionData;
  submitForm(): Promise<void>;
  values: FormValues;
  setFieldValue(fieldName: string, value: boolean): void;
  isValid: boolean;
  discountCode?: ValidatedDiscountCode | undefined;
}

export default function ConfirmCheckout({
  data,
  submitForm,
  values,
  setFieldValue,
  isValid,
  discountCode,
}: ConfirmCheckoutProps) {
  const { totalCost, totalDiscountedCost } = useTotalCostWithShipping(
    true,
    discountCode
  );

  return (
    <div className="my-10 flex flex-col gap-10">
      <div className="flex flex-wrap justify-center text-2xl font-light md:inline-flex md:justify-start">
        <div className="mb-1 h-auto self-end font-light">Totalt:&nbsp;</div>
        <div className="text-3xl font-bold">
          <FormattedPrice
            price={totalCost}
            discountedPrice={totalDiscountedCost}
            isCheckout={true}
          />
        </div>
        &nbsp;
        <div className="mb-1 h-auto self-end font-light">
          {`${data.fields_vat_included ?? ''} och frakt`}
        </div>
      </div>
      <div className="flex w-full flex-col items-center justify-between gap-8 md:flex-row">
        <div className="flex items-center gap-4">
          <input
            className="form-checkbox"
            type="checkbox"
            id="ToS-checkbox"
            checked={values.termsAgreed}
            onChange={() => setFieldValue('termsAgreed', !values.termsAgreed)}
          />
          <label
            htmlFor="ToS-checkbox"
            className="cursor-pointer font-raleway font-bold md:text-xl"
          >
            {data.fields_term_text + ' '}
            <Link
              href="/artikel/Leverans--och-köpvillkor"
              className="text-hlr-green hover:underline"
            >
              {data.fields_term_link}
            </Link>
          </label>
        </div>
        <button
          className={`w-fit rounded-full bg-hlr-gunmetal px-6 py-3 text-lg font-semibold text-white transition-opacity hover:opacity-60 ${
            isValid && values.termsAgreed
              ? 'cursor-pointer'
              : 'cursor-not-allowed opacity-60'
          }`}
          onClick={() => void submitForm()}
        >
          {data.fields_button_text}
        </button>
      </div>
    </div>
  );
}
