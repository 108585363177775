'use client';
import React, { useMemo, useState } from 'react';
import Button from '@/components/general/Button';
import {
  StrapiFAQQuestionData,
  StrapiFAQSectionData,
} from '@/modules/strapiTypes';
import { StrapiRichText } from '@/strapiComponents/StrapiRichText';
import FAQQuestion from './FAQQuestion';
import { getTags } from '@/utils/generalUtils';
import { PageWidth } from '@/utils/strapiUtils';

export function FAQSection(data: StrapiFAQSectionData) {
  const tags = useMemo(() => getTags(data.questions), [data.questions]);

  const [selectedTag, setSelectedTag] = useState<string>('');

  const filteredQuestions: StrapiFAQQuestionData[] = selectedTag
    ? data.questions?.filter((question) =>
        question.tags?.some((tag) => tag.value === selectedTag)
      ) ?? []
    : data.questions ?? [];

  return (
    <div id={data.anchor}>
      {filteredQuestions.length > 0 && (
        <div
          className="px-default-sm py-8 md:px-default"
          style={{ backgroundColor: data.background_color ?? '#f5f5f5' }}
        >
          <div
            className="mx-auto max-w-page-width-mx-160"
            style={{ maxWidth: data.page_width && PageWidth[data.page_width] }}
          >
            <p className="text-pre-header">{data.title}</p>
            <StrapiRichText richText={data.header} />
            {tags.length > 0 && (
              <div className="flex flex-col pt-6 md:flex-row md:space-x-6">
                <p className="my-auto pb-2 text-lg font-bold md:pb-0">
                  {data.filter_label}
                </p>
                <div className="grid grid-cols-2 gap-3 md:flex md:flex-row md:gap-6">
                  {tags.map((tag) => (
                    <div
                      key={tag}
                      onClick={() => {
                        tag === selectedTag
                          ? setSelectedTag('')
                          : setSelectedTag(tag);
                      }}
                      className="cursor-pointer"
                    >
                      {tag === selectedTag ? (
                        <Button
                          variantColor={'darkgrey'}
                          label={tag}
                          additionalClassNames={'w-full text-center text-sm'}
                        />
                      ) : (
                        <Button
                          variantColor={'transparent_darkgrey'}
                          label={tag}
                          additionalClassNames={'w-full text-center text-sm'}
                        />
                      )}
                    </div>
                  ))}
                </div>
              </div>
            )}
            <div className="space-y-4 py-10">
              {filteredQuestions.map((question: StrapiFAQQuestionData) => (
                <FAQQuestion key={question.id} {...question} />
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
