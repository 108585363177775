import React from 'react';

interface ScrollCarouselProps {
  children: React.ReactElement[];
  gap?: number;
  pt?: number;
  pb?: number;
  sidePadding?: boolean;
}

export default function ScrollCarousel({
  children,
  gap = 0,
  pt = 0,
  pb = 0,
  sidePadding = true,
}: ScrollCarouselProps) {
  return (
    <div
      className={`flex overflow-x-auto px-default-sm scrollbar-thin scrollbar-track-hlr-gunmetal/25 scrollbar-thumb-black scrollbar-thumb-rounded-full md:px-default ${
        !!sidePadding ? '' : '!px-0'
      }`}
      style={{
        gap: gap,
        paddingBottom: pb,
        paddingTop: pt,
      }}
    >
      {children}
    </div>
  );
}
