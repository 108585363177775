import { PropsWithChildren } from 'react';

export interface ButtonProps {
  variantColor:
    | 'green'
    | 'blue'
    | 'red'
    | 'transparent'
    | 'grey'
    | 'transparent_darkgrey'
    | 'darkgrey'
    | 'hero';
  additionalClassNames?: string;
  label?: string;
  onClick?: () => void;
  disabled?: boolean;
}
export default function Button({
  variantColor,
  additionalClassNames,
  label,
  onClick,
  disabled = false,
  children,
}: PropsWithChildren<ButtonProps>) {
  const variantClasses = {
    green: 'bg-custom-green text-white',
    blue: 'bg-custom-blue text-white',
    red: 'bg-custom-red text-white',
    transparent:
      'border border-white bg-transparent text-white hover:bg-white/20 transition-all hover:border-transparent hover:opacity-100',
    grey: 'bg-custom-grey text-white',
    transparent_darkgrey:
      'border border-custom-darkgrey bg-transparent hover:bg-black/10 transition-all hover:border-transparent hover:opacity-100',
    darkgrey: 'border border-custom-darkgrey bg-custom-darkgrey text-white',
    hero: 'border border-white bg-custom-darkgrey text-white hover:bg-white/20 transition-all hover:border-transparent hover:opacity-100',
  };
  const styleClasses =
    'flex items-center p-4 px-8 rounded-md px-3 align-middle cursor-pointer transition-opacity hover:opacity-60 font-semibold  ' +
    variantClasses[variantColor] +
    ' ' +
    (additionalClassNames ?? '') +
    (disabled ? 'cursor-not-allowed' : '');

  return (
    <button onClick={onClick} className={styleClasses} disabled={disabled}>
      {label}
      {children}
    </button>
  );
}
