import { useState, useRef } from 'react';
import Image from 'next/image';
import DropdownArrowIcon from '@/icons/dropdown-menu-arrow-black.svg';
import { StrapiFAQQuestionData } from '@/modules/strapiTypes';
import { StrapiRichText } from '@/strapiComponents/StrapiRichText';

export default function FAQQuestion(data: StrapiFAQQuestionData) {
  const [showContent, setShowContent] = useState<boolean>(false);
  const contentRef = useRef<HTMLDivElement>(null);

  return (
    <div className="w-full rounded bg-white shadow-xl">
      <div
        className="flex cursor-pointer flex-col justify-between gap-2 p-4 md:flex-row md:gap-4 md:p-6"
        onClick={() => setShowContent(!showContent)}
      >
        <h3 className="hover:underline">{data.question}</h3>
        <div className="flex items-center md:gap-2">
          <p className="text-sm font-semibold hover:underline md:text-lg">
            {showContent ? 'Minimera' : 'Expandera'}
          </p>
          <Image
            className={`transition-transform duration-300 ${
              showContent ? 'rotate-180' : ''
            }`}
            src={DropdownArrowIcon as string}
            alt="Dropdown Arrow Icon"
          />
        </div>
      </div>
      <div
        className="max-w-3xl overflow-hidden px-4 text-sm transition-all duration-300 md:px-6 md:text-base"
        style={{
          maxHeight: showContent ? contentRef.current?.scrollHeight ?? 0 : 0,
        }}
        ref={contentRef}
      >
        <StrapiRichText richText={data.answer} />
      </div>
      <div
        className={`transition-all duration-300 ${showContent ? 'pb-6' : ''}`}
      />
    </div>
  );
}
