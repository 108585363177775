import { TextArea, TextField } from '@/components/general/TextFields';
import { StrapiCheckoutSectionData } from '@/modules/strapiTypes';

export default function InvoiceCheckoutFields(data: StrapiCheckoutSectionData) {
  return (
    <div className="flex flex-col">
      <div className="flex flex-wrap gap-x-4 gap-y-2 md:mt-2">
        <div className="min-w-formWidth flex-1">
          <TextField
            name="invoiceReference"
            label={data.form_invoice_reference}
            isRequired={false}
          />
        </div>
        <div className="min-w-formWidth flex-1">
          <TextField
            name="emailForDigitalInvoice"
            label={data.form_invoice_digital_email}
            type="email"
            isRequired={false}
            autoComplete="email"
          />
        </div>
      </div>
      <div className="flex-1 md:mt-2">
        <TextArea name="comment" label={data.form_invoice_comments} />
      </div>
    </div>
  );
}
