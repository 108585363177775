export enum PageWidth {
  'px2400' = 2400,
  'px2240' = 2240,
  'px1840' = 1840,
  'px1600' = 1600,
  'px1440' = 1440,
  'px1200' = 1200,
  'px800' = 800,
}

export type PageWidthEnum =
  | 'px2400'
  | 'px2240'
  | 'px1840'
  | 'px1600'
  | 'px1440'
  | 'px1200'
  | 'px800'
  | undefined;
