'use client';

import { useEffect } from 'react';
import { useLimeForm } from '@/context/LimeFormContext';

export function LimeForm({
  lime_form_id,
  field_values,
}: Readonly<{
  lime_form_id?: string;
  field_values?: {
    id?: number;
    field_name?: string;
    field_value?: string;
  }[];
}>) {
  const limeFormContext = useLimeForm();

  useEffect(() => {
    if (limeFormContext && lime_form_id) {
      const limeForm = limeFormContext?.getApi(lime_form_id);
      limeForm?.onReady(() => {
        // Does not work without setTimeout, nice to fix
        setTimeout(() => {
          field_values?.forEach((field) => {
            limeForm.setFieldValue(
              field.field_name ?? '',
              field.field_value?.toString() ?? ''
            );
          });
        });
      });
    }
  }, [field_values, limeFormContext, lime_form_id]);
  return (
    <div>{lime_form_id && <lime-form form-id={lime_form_id}></lime-form>}</div>
  );
}
