'use client';
import { useSearchParams } from 'next/navigation';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { TextField } from '@/components/general/TextFields';
import {
  checkResetPasswordToken,
  resetPassword,
  setPassword,
  SetPasswordResponse,
} from '@/modules/apiClient';
import Button from '@/components/general/Button';
import { StrapiPasswordSectionData } from '@/modules/strapiTypes';
import { PageWidth, PageWidthEnum } from '@/utils/strapiUtils';

export function PasswordSection(data: StrapiPasswordSectionData) {
  const searchParams = useSearchParams();
  const [verificationStatus, setVerificationStatus] =
    useState<SetPasswordResponse>();
  const [requestStatus, setRequestStatus] = useState<SetPasswordResponse>();
  const { userID, token } = useMemo(() => {
    const userID = searchParams?.get('user_id') ?? '';
    const token = searchParams?.get('token') ?? '';
    return { userID, token };
  }, [searchParams]);

  useEffect(() => {
    async function fetchData() {
      try {
        const status = await checkResetPasswordToken(userID, {
          password_reset_token: token,
          new_password: '',
        });
        setVerificationStatus(status);
      } catch {
        setVerificationStatus('failed');
      }
    }
    void fetchData();
  }, [token, userID]);

  const handleSubmit = async (values: PasswordValues) => {
    const status = await setPassword(userID, {
      password_reset_token: token,
      new_password: values.password,
    });
    setRequestStatus(status);
  };

  const [emailSent, setEmailSent] = useState<boolean | undefined>(undefined);
  const handleClick = (values: EmailValues) => {
    const sendResetEmail = async () => {
      if (values.email) {
        await resetPassword({ email: values.email });
        setEmailSent(true);
      }
    };
    void sendResetEmail();
  };

  if (!userID || !token || verificationStatus === 'failed') {
    return (
      <SetPasswordLayout
        title={data.token_expired_title ?? 'Sessionen har löpt ut'}
        titleColor={'text-custom-red'}
        description={
          data.token_expired_description ??
          'Sessionen för att återställa lösenordet har löpt ut. Skicka in en ny begäran om återställning av lösenord.'
        }
        pageWidth={data.page_width}
      >
        <Formik
          initialValues={initialEmailValue}
          onSubmit={handleClick}
          validationSchema={getEmailValidationSchema(data)}
          validateOnBlur={true}
          enableReinitialize={true}
          validateOnMount={true}
        >
          {({ submitForm }) => {
            return (
              <div className="flex flex-col gap-4 pt-6">
                <div className="flex max-w-xl flex-col gap-4">
                  <TextField
                    label={data.forms_email}
                    name="email"
                    type="email"
                    isRequired={true}
                  />
                </div>
                <div className="flex">
                  <Button
                    onClick={() => void submitForm()}
                    variantColor={'darkgrey'}
                    label={data.token_expired_button_label}
                  />
                  {emailSent && (
                    <div className="my-auto ml-4 text-custom-green">
                      {data.email_sent_message}
                    </div>
                  )}
                </div>
              </div>
            );
          }}
        </Formik>
      </SetPasswordLayout>
    );
  }

  if (requestStatus === 'success') {
    return (
      <SetPasswordLayout
        title={data.reset_success_title ?? 'Lyckades!'}
        titleColor={'text-custom-green'}
        description={
          data.reset_success_description ??
          'Ditt lösenord har uppdaterat. Du kan nu logga in med ditt nya lösenord.'
        }
        pageWidth={data.page_width}
      />
    );
  }

  if (requestStatus === 'failed') {
    return (
      <SetPasswordLayout
        title={data.reset_failed_title ?? 'Misslyckades'}
        titleColor="text-custom-red"
        description={
          data.reset_failed_description ??
          'Misslyckades med att uppdatera lösenord. Försök igen eller kontakta oss på info@hlr-konsulten.se'
        }
        pageWidth={data.page_width}
      />
    );
  }

  return (
    <SetPasswordLayout
      title={data.title ?? 'Välj nytt lösenord'}
      description={
        data.description ??
        'Ange nytt lösenord och bekräfta det för att välja nytt lösenord'
      }
      pageWidth={data.page_width}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={getValidationSchema(data)}
        validateOnBlur={true}
        enableReinitialize={true}
        validateOnMount={true}
      >
        {({ submitForm }) => {
          return (
            <div className="flex flex-col gap-4 pt-6">
              <div className="flex max-w-xl flex-col gap-4">
                <TextField
                  label={data.forms_password}
                  name="password"
                  type="password"
                  isRequired={true}
                  autoComplete="new-password"
                />
                <TextField
                  label={data.forms_password_repeated}
                  name="confirmPassword"
                  type="password"
                  isRequired={true}
                  autoComplete="new-password"
                />
              </div>
              <Button
                variantColor={'blue'}
                label={data.submit_button_label}
                onClick={() => void submitForm()}
              />
              <div className="text-sm font-light text-hlr-gunmetal/75">
                {data.password_restriction_text}
              </div>
            </div>
          );
        }}
      </Formik>
    </SetPasswordLayout>
  );
}

interface SetPasswordLayoutProps {
  title: string;
  titleColor?: 'text-custom-green' | 'text-custom-red';
  description: string;
  children?: ReactNode;
  pageWidth?: PageWidthEnum;
}

function SetPasswordLayout({
  title,
  titleColor,
  description,
  children,
  pageWidth,
}: SetPasswordLayoutProps) {
  return (
    <div className="px-default-sm md:px-default">
      <div
        className="mx-auto max-w-page-width-mx-360 py-20"
        style={{ maxWidth: pageWidth && PageWidth[pageWidth] }}
      >
        <h2 className={`max-w-3xl pb-4 ${titleColor ?? ''}`}>{title}</h2>
        <div className="text-body-hero max-w-3xl">{description}</div>
        {children}
      </div>
    </div>
  );
}

interface PasswordValues {
  password: string;
  confirmPassword: string;
}

const initialValues: PasswordValues = {
  password: '',
  confirmPassword: '',
};

interface EmailValues {
  email: string;
}

const initialEmailValue: EmailValues = {
  email: '',
};

function getValidationSchema(data: StrapiPasswordSectionData) {
  return Yup.object({
    password: Yup.string().required(data.error_field_required),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password')], data.error_invalid_password)
      .required(data.error_field_required),
  });
}

function getEmailValidationSchema(data: StrapiPasswordSectionData) {
  return Yup.object({
    email: Yup.string().required(data.error_field_required),
  });
}
