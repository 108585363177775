import * as Sentry from '@sentry/nextjs';
const CURRENCY = 'SEK';

export function fbqWrapper(event: PixelEvent, data: PixelData) {
  try {
    // @ts-expect-error Missing meta-pixel type definition
    if (typeof fbq === 'function') {
      // @ts-expect-error Missing meta-pixel type definition
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      fbq('track', event, data);
    }
  } catch (e) {
    Sentry.captureException(e);
  }
}

export function fbqTrackAddToCart(productIds: ProductId[], totalPrice: number) {
  fbqWrapper('AddToCart', {
    content_ids: productIds,
    content_type: 'product_group',
    value: totalPrice,
    currency: CURRENCY,
  });
}

export function fbqTrackViewProduct(productId: ProductId, price: number) {
  fbqWrapper('ViewContent', {
    content_ids: [productId],
    content_type: 'product_group',
    value: price,
    currency: CURRENCY,
  });
}

export function fbqTrackPurchase(productIds: ProductId[], totalPrice: number) {
  fbqWrapper('Purchase', {
    content_ids: productIds,
    content_type: 'product_group',
    value: totalPrice,
    currency: CURRENCY,
  });
}

// https://developers.facebook.com/docs/meta-pixel/reference
export type PixelEvent = 'ViewContent' | 'AddToCart' | 'Purchase';
export type ProductId = string | number;

export interface PixelData {
  content_category?: string;
  content_ids?: ProductId[];
  content_name?: string;
  content_type?: 'product' | 'product_group';
  contents?: {
    id: ProductId;
    quantity: number;
    [key: string]: unknown;
  }[];
  currency?: string;
  num_items?: number;
  predicted_ltv?: number;
  search_string?: string;
  status?: boolean;
  value?: number;
}
