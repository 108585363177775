import { Dispatch, ReactNode, useMemo } from 'react';
import { StrapiMountingOptionData } from '@/modules/strapiTypes';
import { StrapiImage } from '@/strapiComponents/StrapiImage';
import { StrapiRichText } from '@/strapiComponents/StrapiRichText';
import { scrollToComponent } from '@/utils/generalUtils';
import { MountingOptionAmount } from '@/strapiSections/RentHeartstarterSection/RentHeartstarterSection';

interface MountingOptionCardProps {
  mountingOption: StrapiMountingOptionData;
  mountingOptionAmount: MountingOptionAmount | undefined;
  setMountingOptionAmount: Dispatch<MountingOptionAmount | undefined>;
  selectButtonLabel: string | undefined;
  selectedButtonLabel: string | undefined;
  price?: ReactNode;
}

export function MountingOptionCard({
  mountingOption,
  mountingOptionAmount,
  setMountingOptionAmount,
  selectButtonLabel,
  selectedButtonLabel,
  price,
}: MountingOptionCardProps) {
  const isSelectedMountingOption = useMemo(
    () => mountingOption.id == mountingOptionAmount?.mountingOption?.id,
    [mountingOption.id, mountingOptionAmount?.mountingOption?.id]
  );

  return (
    <div className="flex w-72 shrink-0 flex-col justify-between md:w-96">
      <div>
        {mountingOption.attributes?.image && (
          <StrapiImage
            className="h-36 w-72 rounded-lg bg-white object-contain p-2 brightness-95 md:h-52 md:w-96"
            strapiImage={mountingOption.attributes?.image}
          />
        )}
        <p className="custom-item-name">{mountingOption.attributes?.name}</p>
        <StrapiRichText
          additionalClassNames="my-2 text-sm md:text-base"
          richText={mountingOption.attributes?.description}
        />
      </div>

      <div className="mt-4 flex items-center justify-between">
        <div className="text-lg font-bold">{price}</div>
        <div
          className="cursor-pointer text-xs font-semibold text-white transition-opacity hover:opacity-60"
          onClick={() => {
            setMountingOptionAmount(
              isSelectedMountingOption
                ? undefined
                : { mountingOption: mountingOption, amount: 1 }
            );
            !isSelectedMountingOption &&
              scrollToComponent('selectedHeartstarters', 150);
          }}
        >
          <p
            className={`custom-item-button-text rounded-full px-6 py-1.5 ${
              isSelectedMountingOption ? 'bg-custom-green' : 'bg-hlr-gunmetal'
            }`}
          >
            {isSelectedMountingOption ? selectedButtonLabel : selectButtonLabel}
          </p>
        </div>
      </div>
    </div>
  );
}
