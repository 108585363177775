import { useUserContext } from '@/context/UserContext';
import { updateUser } from '@/modules/apiClient';
import { resetPassword } from '@/modules/apiClient';
import { Formik } from 'formik';
import { TextField } from '@/components/general/TextFields';
import { useState } from 'react';
import Button from '@/components/general/Button';
import { UpdateUserRequest } from '@/modules/apiTypes';
import { StrapiProfileSectionData } from '@/modules/strapiTypes';

export default function UserDetails(data: StrapiProfileSectionData) {
  return (
    <div className="py-default-sm md:py-default">
      <UserDetailsForm {...data} />
      <div className="py-5" />
      <ResetPasswordButton {...data} />
    </div>
  );
}

function UserDetailsForm(data: StrapiProfileSectionData) {
  const { userInfo, authToken, ...userContextUtils } = useUserContext();
  const [userUpdateSuccessful, setUserUpdateSuccessful] = useState<
    boolean | undefined
  >(undefined);
  const handleSubmit = (values: UpdateUserRequest) => {
    const submitUpdate = async () => {
      setUserUpdateSuccessful(undefined);
      const updatedInfo = await updateUser(
        values,
        authToken,
        userInfo?.id ?? ''
      );
      if (updatedInfo !== undefined) {
        userContextUtils.updateUserInfo();
        setUserUpdateSuccessful(true);
      } else {
        setUserUpdateSuccessful(false);
      }
    };
    void submitUpdate();
  };

  return (
    <>
      {userInfo && (
        <Formik
          initialValues={{
            first_name: userInfo?.first_name,
            last_name: userInfo?.last_name,
            email: userInfo?.email,
            phone_number: userInfo?.phone_number,
          }}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {({ submitForm }) => (
            <>
              <strong className="mb-4 block text-lg">
                {data.user_detail_private_header}
              </strong>
              <TextField
                label={data.user_form_email}
                name="email"
                type="email"
                autoComplete="email"
              />
              <div className="flex gap-2">
                <TextField
                  label={data.user_form_first_name}
                  name="first_name"
                  autoComplete="given-name"
                />
                <TextField
                  label={data.user_form_last_name}
                  name="last_name"
                  autoComplete="family-name"
                />
              </div>
              <TextField
                label={data.user_form_phone_number}
                name="phone_number"
                autoComplete="tel"
              />
              <div className="inline-flex pt-4">
                <Button
                  onClick={() => void submitForm()}
                  variantColor={'darkgrey'}
                  label={data.save_details_button ?? ''}
                />
                {userUpdateSuccessful && (
                  <div className="my-auto ml-2 text-custom-green">
                    {data.save_details_success_text}
                  </div>
                )}
              </div>
            </>
          )}
        </Formik>
      )}
    </>
  );
}

function ResetPasswordButton(data: StrapiProfileSectionData) {
  const { userInfo } = useUserContext();
  const [emailSent, setEmailSent] = useState<boolean | undefined>(undefined);
  const handleClick = () => {
    const sendResetEmail = async () => {
      userInfo && (await resetPassword({ email: userInfo.email }));
      setEmailSent(true);
    };
    void sendResetEmail();
  };
  return (
    <div>
      <strong className="mb-3 block text-lg">
        {data.reset_password_header}
      </strong>
      <div className="inline-flex">
        <Button
          onClick={handleClick}
          variantColor={'darkgrey'}
          label={data.reset_password_button}
        />
        {emailSent && (
          <div className="my-auto ml-2 text-custom-green">
            {data.reset_password_request_success_text}
          </div>
        )}
      </div>
    </div>
  );
}
