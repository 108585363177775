import { StrapiVideoData } from '@/modules/strapiTypes';

export function VideoCard(data: StrapiVideoData) {
  if (data.attributes?.url === undefined) {
    return null;
  }

  const videoId = stripUrl(data.attributes.url);

  return (
    <div className="h-full w-64 rounded-lg bg-white shadow-xl md:w-96">
      <div className="overflow-hidden rounded-t-lg">
        <lite-youtube videoid={videoId} />
      </div>
      <div className="space-y-2 p-4 md:p-6">
        <p className="custom-item-category">{data.attributes.category}</p>
        <p className="custom-item-name">{data.attributes.title}</p>
        <p className="text-sm md:text-base">{data.attributes.description}</p>
      </div>
    </div>
  );
}

const toStrip = ['https://youtu.be/', 'https://www.youtube.com/watch?v='];

function stripUrl(id: string): string {
  return toStrip.reduce((newId, prefix) => {
    if (newId.startsWith(prefix)) {
      return newId.slice(prefix.length);
    } else {
      return newId;
    }
  }, id);
}
