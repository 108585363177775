import { TextField } from '@/components/general/TextFields';
import CountrySelector from '@/components/general/CountrySelector';
import { AddressFieldsProps } from './CheckoutForm';

export default function DeliveryAddressFields({
  companyCheckout,
  data,
}: AddressFieldsProps) {
  return (
    <div className="flex flex-col gap-2">
      <strong className="mb-2 font-raleway text-xl">
        {data.fields_delivery_address_header}
      </strong>
      <div className="flex flex-wrap gap-x-4 gap-y-2">
        <div className="min-w-formWidth flex-1">
          <TextField
            name="deliveryInfo.firstName"
            label={data.form_delivery_first_name}
            isRequired={true}
            autoComplete="given-name"
          />
        </div>
        <div className="min-w-formWidth flex-1">
          <TextField
            name="deliveryInfo.lastName"
            label={data.form_delivery_last_name}
            isRequired={true}
            autoComplete="family-name"
          />
        </div>
      </div>
      <div className="flex flex-wrap gap-x-4 gap-y-2">
        <div className="min-w-formWidth flex-1">
          <TextField
            name="deliveryInfo.email"
            label={data.form_delivery_email}
            type="email"
            isRequired={true}
            autoComplete="email"
          />
        </div>
        <div className="min-w-formWidth flex-1">
          <TextField
            name="deliveryInfo.phone"
            label={data.form_delivery_phone}
            type="tel"
            isRequired={true}
            autoComplete="tel"
          />
        </div>
      </div>

      {companyCheckout && (
        <div className="flex flex-wrap gap-x-4 gap-y-2">
          <div className="min-w-formWidth flex-1">
            <TextField
              name="deliveryInfo.companyName"
              label={data.form_delivery_company}
              isRequired={true}
              autoComplete="organization"
            />
          </div>
          <div className="min-w-formWidth flex-1">
            <TextField
              name="deliveryInfo.orgNr"
              label={data.form_delivery_organization}
            />
          </div>
        </div>
      )}
      <TextField
        name="deliveryInfo.address"
        label={data.form_delivery_address_primary}
        isRequired={true}
        autoComplete="address-line1"
      />

      <TextField
        name="deliveryInfo.address2"
        label={data.form_delivery_address_secondary}
        autoComplete="address-line2"
      />
      <div className="flex flex-wrap gap-x-4 gap-y-2">
        <div className="min-w-formWidth flex-1">
          <TextField
            name="deliveryInfo.postNr"
            label={data.form_delivery_postal_code}
            isRequired={true}
            autoComplete="postal-code"
          />
        </div>
        <div className="min-w-formWidth flex-1">
          <TextField
            name="deliveryInfo.city"
            label={data.form_delivery_city}
            isRequired={true}
            autoComplete="address-level2"
          />
        </div>
      </div>
      <div className="min-w-formWidth flex-1">
        <CountrySelector
          name="deliveryInfo.country"
          label={data.form_delivery_country}
          locale="sv"
          isRequired={true}
          autoComplete="country"
        />
      </div>
    </div>
  );
}
