'use client';
import {
  StrapiBuyHeartstarterSectionData,
  StrapiHeartstarterData,
  StrapiMountingOptionData,
} from '@/modules/strapiTypes';
import { HeartstarterSection } from '../HeartstarterSection/HeartstarterSection';
import { useState } from 'react';
import SelectedProductAmountsCart from './SelectedProductAmountsCart';

export interface HeartstarterAmount {
  heartstarter: StrapiHeartstarterData;
  amount: number;
}

export interface MountingOptionAmount {
  mountingOption: StrapiMountingOptionData;
  amount: number;
}

export function BuyHeartstarterSection(data: StrapiBuyHeartstarterSectionData) {
  const [heartstarterAmount, setHeartstarterAmount] =
    useState<HeartstarterAmount>();

  const [mountingOptionAmount, setMountingOptionAmount] =
    useState<MountingOptionAmount>();

  return (
    <div id={data.anchor}>
      <HeartstarterSection
        data={data}
        displayMode="buy"
        heartstarterAmount={heartstarterAmount}
        setHeartstarterAmount={setHeartstarterAmount}
        mountingOptionAmount={mountingOptionAmount}
        setMountingOptionAmount={setMountingOptionAmount}
        pageWidth={data.page_width}
      />
      {heartstarterAmount && (
        <SelectedProductAmountsCart
          productAmountHeader={data.chosen_products_header}
          backgroundColor={data.cart_background_color}
          heartstarterAmount={heartstarterAmount}
          setHeartstarterAmount={setHeartstarterAmount}
          mountingOptionAmount={mountingOptionAmount}
          setMountingOptionAmount={setMountingOptionAmount}
          pageWidth={data.page_width}
        />
      )}
    </div>
  );
}
