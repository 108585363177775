import { TextField } from '@/components/general/TextFields';
import CountrySelector from '@/components/general/CountrySelector';
import { AddressFieldsProps } from './CheckoutForm';

export default function BillingAddressFields({
  companyCheckout,
  data,
}: AddressFieldsProps) {
  return (
    <div className="flex flex-col gap-2 pt-10">
      <strong className="mb-2 font-raleway text-xl">
        {data.fields_billing_address_header}
      </strong>
      <div className="flex flex-wrap gap-x-4 gap-y-2">
        <div className="min-w-formWidth flex-1">
          <TextField
            name="billingInfo.email"
            label={data.form_billing_email}
            type="email"
            isRequired={true}
            autoComplete="email"
          />
        </div>
        <div className="min-w-formWidth flex-1">
          <TextField
            name="billingInfo.phone"
            label={data.form_billing_phone_number}
            type="tel"
            isRequired={true}
            autoComplete="tel"
          />
        </div>
      </div>
      <div className="flex flex-wrap gap-x-4 gap-y-2">
        <div className="min-w-formWidth flex-1">
          <TextField
            name="billingInfo.firstName"
            label={data.form_billing_first_name}
            isRequired={true}
            autoComplete="given-name"
          />
        </div>
        <div className="min-w-formWidth flex-1">
          <TextField
            name="billingInfo.lastName"
            label={data.form_billing_last_name}
            isRequired={true}
            autoComplete="family-name"
          />
        </div>
      </div>
      {companyCheckout && (
        <div className="flex flex-wrap gap-x-4 gap-y-2">
          <div className="min-w-formWidth flex-1">
            <TextField
              name="billingInfo.companyName"
              label={data.form_billing_company}
              isRequired={true}
              autoComplete="organization"
            />
          </div>
          <div className="min-w-formWidth flex-1">
            <TextField
              name="billingInfo.orgNr"
              label={data.form_billing_organization}
              isRequired={true}
            />
          </div>
        </div>
      )}

      <div className="flex-1">
        <TextField
          name="billingInfo.address"
          label={data.form_billing_address_primary}
          isRequired={true}
          autoComplete="address-line1"
        />
      </div>
      <div className="flex-1">
        <TextField
          name="billingInfo.address2"
          label={data.form_billing_address_secondary}
          autoComplete="address-line2"
        />
      </div>
      <div className="flex flex-wrap gap-x-4 gap-y-2">
        <div className="min-w-formWidth flex-1">
          <TextField
            name="billingInfo.postNr"
            label={data.form_billing_postal_code}
            isRequired={true}
            autoComplete="postal-code"
          />
        </div>
        <div className="min-w-formWidth flex-1">
          <TextField
            name="billingInfo.city"
            label={data.form_billing_city}
            isRequired={true}
            autoComplete="address-level2"
          />
        </div>
      </div>
      <div className="flex-1">
        <CountrySelector
          name="billingInfo.country"
          label={data.form_billing_country}
          locale="sv"
          isRequired={true}
          autoComplete="country"
        />
      </div>
    </div>
  );
}
