import {
  StrapiFormSectionData,
  StrapiLimeFormData,
} from '@/modules/strapiTypes';
import { StrapiImage } from '@/strapiComponents/StrapiImage';
import { StrapiRichText } from '@/strapiComponents/StrapiRichText';
import Link from 'next/link';

export function BaseContactInformation(
  data: StrapiFormSectionData | StrapiLimeFormData
) {
  return (
    <div>
      <p className="text-pre-header mb-4">{data.attributes?.top_header}</p>
      <StrapiRichText richText={data.attributes?.h2_header} />
      <div className="custom-divider-red mb-4 md:mb-8" />
      <StrapiRichText
        additionalClassNames="text-body-hero order-1 mb-4 md:mb-10 md:order-2"
        richText={data.attributes?.description ?? []}
      />
      <p className="font-bold md:mt-4">
        {data.attributes?.contact_list_header}
      </p>
      {data.attributes?.items?.map((item, index) => (
        <div className="mt-4 flex items-center" key={item.id ?? index}>
          {!!item.icon && (
            <StrapiImage className="h-6 w-6" strapiImage={item.icon} />
          )}
          <Link
            className="ml-3 font-bold hover:underline"
            href={item.url ?? ''}
          >
            {item.display_text}
          </Link>
        </div>
      ))}
    </div>
  );
}
