'use client';
import { useState, useEffect } from 'react';
import {
  ShoppingCartAction,
  useShoppingCart,
} from '@/context/shoppingCartContext';
import QuantitySelector from '@/components/general/QuantitySelector';
import { WebshopProduct } from '@/modules/apiTypes';
import { sendEcommerceEvent } from '@/modules/googleAnalytics';
import { calculateTotalCost } from '@/utils/cartUtils';
import { fbqTrackAddToCart } from '@/modules/metaPixel';
import { useNavbarModal } from '@/context/NavbarModalsContext';
import { ModalVisibility } from '@/strapiComponents/NavbarModal/NavbarModal';

interface AmountBuyButtonProps {
  sizeVariant: 'small' | 'big';
  showQuantitySelector?: boolean;
  product: WebshopProduct;
  additionalOnClickFunction?(): void;
}

export default function AmountBuyButton({
  sizeVariant,
  showQuantitySelector = true,
  product,
  additionalOnClickFunction = () => null,
}: Readonly<AmountBuyButtonProps>) {
  const [amount, setAmount] = useState(1);
  const [buttonText, setButtonText] = useState('Köp');
  const { updateShoppingCart } = useShoppingCart();
  const checkmarkString = '\u2713';
  const { setNavbarModalVisible } = useNavbarModal();

  function handleAmountChange(newAmount: number) {
    if (newAmount >= 1) {
      setAmount(newAmount);
    }
  }

  function handleIncrementClick() {
    setAmount(amount + 1);
  }

  function handleDecrementClick() {
    if (amount > 1) {
      setAmount(amount - 1);
    }
  }

  const sendCartAnalyticsEvent = (
    action: string,
    amount: number,
    product: WebshopProduct
  ) => {
    sendEcommerceEvent({
      name: action,
      value: calculateTotalCost(false, [{ amount, product }], undefined)
        .totalDiscountedCost,
      currency: 'SEK',
      items: [
        {
          item_id: product.id.toString(),
          item_name: product.name,
          price: Number(product.custom_price_excl_vat ?? 0),
          quantity: amount,
        },
      ],
    });
  };

  function handleBuyClick() {
    setButtonText(checkmarkString);
    updateShoppingCart({
      type: ShoppingCartAction.ADD,
      payload: { amount: amount, id: product.id.toString() },
    });
    sendCartAnalyticsEvent('add_to_cart', amount, product);

    const { totalDiscountedCost } = calculateTotalCost(
      false,
      [{ amount, product }],
      undefined
    );
    fbqTrackAddToCart([product.id], totalDiscountedCost);

    additionalOnClickFunction();
    setNavbarModalVisible(ModalVisibility.Cart);
  }

  useEffect(() => {
    if (buttonText === checkmarkString) {
      const timeout = setTimeout(() => {
        setButtonText('Köp');
      }, 500);

      return () => clearTimeout(timeout);
    }
  }, [buttonText]);

  return (
    <div
      className="flex items-center"
      onClick={(e) => {
        e.preventDefault();
      }}
    >
      {sizeVariant === 'big' ? (
        <div className="inline-flex gap-2">
          {showQuantitySelector && (
            <QuantitySelector
              amount={amount}
              sizeVariant={'big'}
              handleIncrementClick={handleIncrementClick}
              handleDecrementClick={handleDecrementClick}
              handleAmountChange={handleAmountChange}
            />
          )}
          <button
            className="rounded-full bg-hlr-gunmetal px-7 py-1 text-sm text-white transition-opacity hover:opacity-60"
            onClick={handleBuyClick}
          >
            {buttonText}
          </button>
        </div>
      ) : (
        <button
          className="inline-flex gap-1 rounded-full bg-hlr-gunmetal px-7 py-1 text-sm font-bold text-white transition-opacity hover:opacity-60"
          onClick={handleBuyClick}
        >
          {buttonText}
        </button>
      )}
    </div>
  );
}
