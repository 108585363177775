'use client';
import Link from 'next/link';
import AmountBuyButton from '@/components/general/AmountBuyButton';
import React from 'react';
import { WebshopProduct } from '@/modules/apiTypes';
import { ProductPrice } from '../pageStore/ProductPrice';
import { useShoppingCart } from '@/context/shoppingCartContext';
import Media from './Media';
import { Image as ImageType } from '@/modules/apiTypes';
import { productUrl } from '@/modules/helper';

export interface ProductCardProps {
  product: WebshopProduct;
  productCategoryName: string;
  onClick?: () => void;
}

export default function ProductCard({
  product,
  productCategoryName,
  onClick,
}: ProductCardProps) {
  const { displayVat } = useShoppingCart();
  const firstMedia = product.images.at(0);
  const mainMedia: ImageType = {
    url: `${firstMedia?.url ?? '/images/logo.png'}`,
    alt: firstMedia?.alt ?? '',
    id: firstMedia?.id ?? 0,
    mediaType: firstMedia?.mediaType,
  };

  const price = !!displayVat
    ? Number(product.price_incl_vat)
    : Number(product.price_excl_vat);
  const discountedPrice = !!displayVat
    ? Number(product.custom_price_incl_vat)
    : Number(product.custom_price_excl_vat);
  const discountPercentage = Math.trunc((1 - discountedPrice / price) * 100);

  return (
    <Link
      className="group flex w-32 flex-col justify-between self-stretch md:w-56"
      href={productUrl(product)}
      onClick={onClick}
    >
      <div className="transition-opacity group-hover:opacity-60">
        <div className="relative h-24 w-32 overflow-hidden rounded-lg bg-white brightness-95 md:h-48 md:w-56 md:rounded-xl">
          {product.top_badge && (
            <div className="absolute -left-2 top-0 z-10 w-24 -skew-x-20 bg-custom-red py-1 pl-1 opacity-80 group-hover:opacity-80 md:w-40 md:opacity-100">
              <p className="ml-2 skew-x-12 text-xs font-bold text-white md:ml-4 md:text-base">
                {product.top_badge}
              </p>
            </div>
          )}
          {product.show_discount_badge && discountPercentage > 0 && (
            <div className="absolute right-1 top-1/2 z-10 flex h-11 w-11 -translate-y-1/2 items-center justify-center rounded-full bg-custom-red opacity-80 group-hover:opacity-80 md:right-2 md:top-2 md:h-16 md:w-16 md:translate-y-0 md:opacity-100">
              <p className="text-base font-bold italic text-white md:text-2xl">
                {`-${discountPercentage}%`}
              </p>
            </div>
          )}
          <Media
            media={mainMedia}
            imageAdditionalClasses="aspect-square rounded-lg object-cover p-2 md:rounded-xl"
            videoAdditionalClasses="aspect-square h-full w-full rounded-lg object-cover p-2 md:rounded-xl"
            sizes="(max-width: 768px) 25vw, 20vw"
          />
          {product.bottom_badge && (
            <div className="absolute -right-2 bottom-0 z-10 w-24 -skew-x-20 bg-hlr-gunmetal py-1 pr-1 opacity-80 group-hover:opacity-80 md:w-40 md:opacity-100">
              <p className="mr-2 skew-x-12 text-end text-xs font-bold text-white md:mr-4 md:text-base">
                {product.bottom_badge}
              </p>
            </div>
          )}
        </div>
        <p className="custom-item-category mb-2 mt-4 truncate uppercase tracking-hlr-category">
          {productCategoryName}
        </p>
        <p className="text-sm font-bold hover:underline md:mb-4 md:text-base">
          {product.name}
        </p>
      </div>
      <div className="flex flex-col md:flex-row md:items-center">
        <div className="mr-auto text-lg font-bold">
          <ProductPrice displayVat={displayVat} product={product} />
        </div>
        <AmountBuyButton
          sizeVariant={'small'}
          showQuantitySelector={false}
          product={product}
        />
      </div>
    </Link>
  );
}
