'use client';
import Link from 'next/link';
import Image from 'next/image';
import CaretRight from '@/icons/caret-right.svg';
import { OrderConfirmedSectionData } from '@/modules/strapiTypes';
import { StrapiRichText } from '@/strapiComponents/StrapiRichText';
import { useSearchParams } from 'next/navigation';
import { PageWidth } from '@/utils/strapiUtils';

export function OrderConfirmedSection(data: OrderConfirmedSectionData) {
  const searchParams = useSearchParams();

  return (
    <div className="mx-default-sm md:mx-default">
      <div
        className="mx-auto mb-20 mt-10 max-w-page-width-mx-360"
        style={{ maxWidth: data.page_width && PageWidth[data.page_width] }}
      >
        <div className="mb-6 flex shrink-0 gap-2">
          <p className="my-auto font-semibold hover:underline md:text-lg">
            <Link href={`/butik`}>{data.breadcrumb_store}</Link>
          </p>
          <Image
            src={CaretRight as string}
            alt="CaretRight"
            height="24"
            width="24"
          />
          <p className="my-auto md:text-lg">{data.breadcrumb_order_complete}</p>
        </div>
        <h2 className="font-bold">{data.h2_header}</h2>
        <h2>
          {data.h2_order_number_text?.replace(
            '<N>',
            searchParams?.get('order_id') ?? ''
          )}
        </h2>
        <StrapiRichText
          additionalClassNames="mt-6 space-y-4 divide-y divide-transparent"
          richText={data.description}
        />
      </div>
    </div>
  );
}
