'use client';
// Inspired Carousel implementation by rakumairu https://github.com/rakumairu/simple-react-carousel

import {
  ReactElement,
  TouchEventHandler,
  useEffect,
  useRef,
  useState,
} from 'react';

interface CarouselProps {
  children: ReactElement[] | ReactElement;
  show?: number;
  showButtons?: boolean;
  showIndicators?: boolean;
  insetIndicators?: boolean;
  arrowPosition?:
    | 'neg4'
    | 'neg3'
    | 'neg2'
    | 'neg1'
    | 'zero'
    | 'pos1'
    | 'pos2'
    | 'pos3'
    | 'pos4';
  autoScroll?: boolean;
  selectedIndicatorColor?: 'black' | 'white';
}

function childrenArray(
  children: ReactElement[] | ReactElement
): ReactElement[] {
  if (!Array.isArray(children)) {
    return [children];
  }
  return children;
}

export function Carousel({
  children: childrenProps,
  show = 1,
  showButtons = true,
  showIndicators = true,
  insetIndicators = false,
  arrowPosition = 'zero',
  autoScroll = false,
  selectedIndicatorColor = 'black',
}: CarouselProps) {
  const children = childrenArray(childrenProps);

  const arrowPositionVariantClasses = {
    neg4: '-4rem',
    neg3: '-3rem',
    neg2: '-2rem',
    neg1: '-1rem',
    zero: '0rem',
    pos1: '1rem',
    pos2: '2rem',
    pos3: '3rem',
    pos4: '4rem',
  };

  const selectedIndicatorColorVariantClasses = {
    black: 'bg-black',
    white: 'bg-white',
  };

  const [currentIndex, setCurrentIndex] = useState(0);

  const touchRef = useRef<number | null>(null);

  useEffect(() => {
    if (autoScroll) {
      const next = currentIndex < children.length - show ? currentIndex + 1 : 0;
      const id = setTimeout(() => setCurrentIndex(next), 7000);
      return () => clearTimeout(id);
    }
  }, [currentIndex, autoScroll, children.length, show]);

  let displayWidth = '[&>*]:w-[100%]';

  switch (show) {
    case 2:
      displayWidth = '[&>*]:w-[50%]';
      break;
    case 3:
      displayWidth = '[&>*]:w-[33%]';
      break;
    case 4:
      displayWidth = '[&>*]:w-[25%]';
      break;
    case 5:
      displayWidth = '[&>*]:w-[20%]';
      break;
  }

  const next = () => {
    if (currentIndex < children.length - show) {
      setCurrentIndex((prevState) => prevState + 1);
    }
  };

  const prev = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevState) => prevState - 1);
    }
  };

  const goTo = (newState: number) => {
    setCurrentIndex(newState);
  };

  const handleTouchStart: TouchEventHandler = (e) => {
    touchRef.current = e.touches[0].clientX;
  };

  const handleTouchMove: TouchEventHandler = (e) => {
    const touchDown = touchRef.current;

    if (touchDown === null) {
      return;
    }

    const currentTouch = e.touches[0].clientX;
    const diff = touchDown - currentTouch;

    if (diff > 5) {
      next();
    }

    if (diff < -5) {
      prev();
    }

    touchRef.current = null;
  };

  const startIndex = () => {
    return currentIndex == 0;
  };

  const endIndex = () => {
    return currentIndex == children.length - show;
  };

  const highlightIndex = (index: number) => {
    return currentIndex == index;
  };

  return (
    <>
      <div className="relative flex h-full">
        {showButtons && (
          <button
            onClick={prev}
            className="absolute top-1/2 z-[1] -translate-y-1/2"
            style={{
              left: arrowPositionVariantClasses[arrowPosition],
            }}
            disabled={startIndex()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              fill="currentColor"
              className={startIndex() ? 'opacity-50' : 'opacity-100'}
              viewBox="0 0 16 16"
            >
              <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z" />
            </svg>
          </button>
        )}
        <div
          className="w-full overflow-x-clip overflow-y-visible"
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
        >
          <div
            className={`flex h-full transition-all duration-500 ease-linear [&>*]:shrink-0 [&>*]:grow ${displayWidth}`}
            style={{
              transform: `translateX(-${currentIndex * (100 / show)}%)`,
              WebkitTransform: `translateX(-${currentIndex * (100 / show)}%)`,
            }}
          >
            {children}
          </div>
        </div>
        {showButtons && (
          <button
            onClick={next}
            className="absolute top-1/2 z-[1] -translate-y-1/2"
            style={{
              right: arrowPositionVariantClasses[arrowPosition],
            }}
            disabled={endIndex()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              fill="currentColor"
              className={endIndex() ? 'opacity-50' : 'opacity-100'}
              viewBox="0 0 16 16"
            >
              <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
            </svg>
          </button>
        )}
      </div>
      {showIndicators && children.length > 1 && (
        <div
          className={`relative z-0 flex justify-center ${
            insetIndicators ? '-top-8' : ''
          }`}
        >
          {children
            .slice(0, children.length - show + 1)
            .map((_, index: number) => (
              <button
                onClick={() => goTo(index)}
                key={children[index].key}
                className="mx-1.5"
              >
                <div
                  className={`h-3 w-3 rounded-full ${
                    highlightIndex(index)
                      ? selectedIndicatorColorVariantClasses[
                          selectedIndicatorColor
                        ]
                      : 'bg-gray-400'
                  }`}
                />
              </button>
            ))}
        </div>
      )}
    </>
  );
}
