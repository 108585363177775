import { useUserContext } from '@/context/UserContext';
import { updateCustomer } from '@/modules/apiClient';
import { Formik } from 'formik';
import { TextField } from '@/components/general/TextFields';
import { useEffect, useState } from 'react';
import Button from '@/components/general/Button';
import CountrySelector from '@/components/general/CountrySelector';
import { UpdateCustomerRequest } from '@/modules/apiTypes';
import { StrapiProfileSectionData } from '@/modules/strapiTypes';

export default function CustomerDetails(data: StrapiProfileSectionData) {
  return (
    <div className="py-default-sm md:py-default">
      <CustomerDetailsForm {...data} />
      <div className="py-5" />
    </div>
  );
}

function CustomerDetailsForm(data: StrapiProfileSectionData) {
  const { selectedCustomer, authToken, ...userContextUtils } = useUserContext();
  const [customerUpdateSuccessful, setCustomerUpdateSuccessful] = useState<
    boolean | undefined
  >(undefined);
  const handleSubmit = (values: UpdateCustomerRequest) => {
    const submitUpdate = async () => {
      setCustomerUpdateSuccessful(undefined);
      const updatedInfo = await updateCustomer(
        values,
        authToken,
        selectedCustomer?.id ?? ''
      );
      if (updatedInfo !== undefined) {
        userContextUtils.updateUserInfo();
        setCustomerUpdateSuccessful(true);
      } else {
        setCustomerUpdateSuccessful(false);
      }
    };
    void submitUpdate();
  };

  useEffect(() => setCustomerUpdateSuccessful(undefined), [selectedCustomer]);

  return (
    <>
      {selectedCustomer && (
        <Formik
          initialValues={{
            customer_type: selectedCustomer.customer_type,
            first_name: selectedCustomer.first_name,
            last_name: selectedCustomer.last_name,
            company_name: selectedCustomer.company_name,
            organization_number: selectedCustomer.organization_number,
            phone_number: selectedCustomer.phone_number,
            country: selectedCustomer.country,
            address: selectedCustomer.address,
            address2: selectedCustomer.address2,
            zip_code: selectedCustomer.zip_code,
            city: selectedCustomer.city,
            email: selectedCustomer.email,
          }}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {({ submitForm }) => (
            <>
              {selectedCustomer.customer_type === 'company' ? (
                <>
                  <strong className="mb-3 block text-lg">
                    {data.user_detail_company_header?.replace(
                      '<C>',
                      selectedCustomer.company_name ?? ''
                    )}
                  </strong>
                  <TextField
                    label={data.form_company}
                    name="company_name"
                    autoComplete="organization"
                  />
                  <TextField
                    label={data.form_organization}
                    name="organization_number"
                  />
                  <TextField
                    label={data.form_email}
                    name="email"
                    type="email"
                    autoComplete="email"
                  />
                </>
              ) : (
                <strong className="mb-4 block text-lg">
                  {data.user_detail_private_header}
                </strong>
              )}
              <div className="flex gap-2">
                <TextField
                  label={data.form_first_name}
                  name="first_name"
                  autoComplete="given-name"
                />
                <TextField
                  label={data.form_last_name}
                  name="last_name"
                  autoComplete="family-name"
                />
              </div>
              <TextField
                label={data.form_phone_number}
                name="phone_number"
                autoComplete="tel"
              />
              <TextField
                label={data.form_address_primary}
                name="address"
                autoComplete="address-line1"
              />
              <TextField
                label={data.form_address_secondary}
                name="address2"
                autoComplete="address-line2"
              />
              <div className="flex gap-2">
                <TextField
                  label={data.form_postal_code}
                  name="zip_code"
                  autoComplete="postal-code"
                />
                <TextField
                  label={data.form_city}
                  name="city"
                  autoComplete="address-level2"
                />
              </div>
              <CountrySelector
                name="country"
                label={data.form_country}
                locale="sv"
                autoComplete="country"
              />
              <div className="inline-flex pt-4">
                <Button
                  onClick={() => void submitForm()}
                  variantColor={'darkgrey'}
                  label={data.save_details_button ?? ''}
                />
                {customerUpdateSuccessful && (
                  <div className="my-auto ml-2 text-custom-green">
                    {data.save_details_success_text}
                  </div>
                )}
              </div>
            </>
          )}
        </Formik>
      )}
    </>
  );
}
