'use client';
import ProductCard from '@/components/general/ProductCard';
import Link from 'next/link';
import ScrollCarousel from '@/components/general/ScrollCarousel';
import { StrapiPopularProductsData } from '@/modules/strapiTypes';
import { StrapiRichText } from '@/strapiComponents/StrapiRichText';
import { useEffect, useState } from 'react';
import { WebshopProduct } from '@/modules/apiTypes';
import { useUserContext } from '@/context/UserContext';
import { PageWidth } from '@/utils/strapiUtils';
import { getProducts } from '@/modules/productMapCache';

interface ExtraProps {
  prefetchedProducts: WebshopProduct[];
}

export function PopularProductsSection({
  top_header,
  h2_header,
  description,
  products,
  section_link,
  anchor,
  page_width,
  prefetchedProducts,
}: StrapiPopularProductsData & ExtraProps) {
  const [selectedProducts, setSelectedProducts] =
    useState<WebshopProduct[]>(prefetchedProducts);
  const { selectedCustomer, authToken } = useUserContext();

  useEffect(() => {
    let isValid = true;

    void (async () => {
      const productIds =
        products?.data?.map((p) => p.attributes?.product_id ?? '') ?? [];
      const customerProducts = await getProducts(
        productIds,
        selectedCustomer,
        authToken
      );
      if (isValid && customerProducts !== undefined) {
        const productMap = new Map(customerProducts.map((p) => [p.id, p]));
        const sortedProducts = productIds
          ?.map((id) => productMap.get(Number(id)) as WebshopProduct)
          .filter((product) => product !== undefined);
        setSelectedProducts(sortedProducts ?? []);
      }
    })();

    return () => {
      isValid = false;
    };
  }, [authToken, products, selectedCustomer]);

  return (
    <>
      <div
        className="mx-auto my-8 max-w-page-width-mx-80"
        id={anchor}
        style={{ maxWidth: page_width && PageWidth[page_width] }}
      >
        <div className="flex flex-col justify-between gap-4 px-default-sm md:mr-default md:flex-row md:px-default">
          <div>
            {top_header && <p className="text-pre-header mb-2">{top_header}</p>}
            {h2_header && <h2 className="mb-2">{h2_header}</h2>}
            <div className="text-body-hero">
              <StrapiRichText richText={description} />
            </div>
          </div>
          {section_link && (
            <Link
              className="flex shrink-0 flex-col md:self-center"
              href={section_link.url ?? ''}
            >
              <h3 className="text-lg hover:underline">
                {section_link.display_name ?? ''} &rarr;
              </h3>
            </Link>
          )}
        </div>
        <ScrollCarousel gap={24} pt={24} pb={16}>
          {selectedProducts.map((product) => (
            <ProductCard
              key={product.name}
              product={product}
              productCategoryName={''}
            />
          ))}
        </ScrollCarousel>
      </div>
    </>
  );
}
