'use client';

import { renderStrapiRichText } from '@/modules/strapiRichText';
import { RootNode } from '@strapi/blocks-react-renderer/dist/BlocksRenderer';

interface StrapiRichTextProps {
  richText: unknown;
  additionalClassNames?: string;
}

export function StrapiRichText({
  richText,
  additionalClassNames,
}: StrapiRichTextProps) {
  if (isValidStrapiRichText(richText)) {
    if (additionalClassNames) {
      return (
        <div className={additionalClassNames}>
          {renderStrapiRichText(richText)}
        </div>
      );
    }
    return <>{renderStrapiRichText(richText)}</>;
  }
  return null;
}

export function isValidStrapiRichText(richText: unknown): boolean {
  const text = richText as RootNode[];
  if (!text) {
    return false;
  }

  if (text.length == 0) {
    return false;
  }

  if (text.length > 1) {
    return true;
  }

  // @ts-expect-error text is somehow seen as undefined but it is
  if (!text[0].children[0].text) {
    return false;
  }

  return true;
}
