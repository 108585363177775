import { newOrder } from '@/modules/apiClient';
import { Dispatch } from 'react';
import {
  Person,
  Order,
  Customer,
  ShoppingCartProduct,
  NewOrderRequest,
  ValidatedDiscountCode,
} from '@/modules/apiTypes';
import {
  AddressValues,
  FormValues,
} from '@/strapiSections/CheckoutSection/CheckoutForm';

export function toPerson(addressValues: AddressValues): Person {
  return {
    first_name: addressValues.firstName,
    last_name: addressValues.lastName,
    email: addressValues.email,
    phone_number: addressValues.phone,
    address: {
      zip_code: addressValues.postNr,
      city: addressValues.city,
      address: addressValues.address,
      address2: addressValues.address2,
      country: addressValues.country,
    },
    org_number: addressValues.orgNr,
    company_name: addressValues.companyName,
  };
}

export function createNewInvoiceOrder(
  products: ShoppingCartProduct[],
  formValues: FormValues,
  setOrder: (order: Order) => void,
  authToken: string | undefined,
  selectedCustomer: Customer | undefined,
  setDisplayOrderCreationFailureModal: Dispatch<boolean>,
  displayVat: boolean,
  shippingAlternative: number | undefined,
  discountCode: ValidatedDiscountCode | undefined
) {
  const awaitOrder = async () => {
    const orderRequest: NewOrderRequest = {
      products: products,
      local_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      recipient: formValues.useSameAddress
        ? toPerson(formValues.billingInfo)
        : toPerson(formValues.deliveryInfo),
      buyer: toPerson(formValues.billingInfo),
      new_order_type: {
        type: 'new_invoice_order',
      },
      incl_vat: displayVat,
      invoice_reference: formValues.invoiceReference,
      email_for_digital_invoice: formValues.emailForDigitalInvoice,
      comment: formValues.comment,
      shipping_alternative: shippingAlternative,
      discount_code: discountCode?.discount_code,
    };
    const order = await newOrder(orderRequest, selectedCustomer, authToken);
    if (order) {
      setOrder(order);
    } else {
      setDisplayOrderCreationFailureModal(true);
    }
  };
  void awaitOrder();
}

export interface NewInvoiceOrder {
  type: 'new_invoice_order';
}
