import { Field, FormikErrors, FormikTouched } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import {
  ShoppingCartAction,
  useShoppingCart,
} from '@/context/shoppingCartContext';
import { useUserContext } from '@/context/UserContext';
import { getProductRequest } from '@/modules/apiClient';
import {
  StrapiCheckoutSectionData,
  StrapiProductData,
} from '@/modules/strapiTypes';
import { StrapiRichText } from '@/strapiComponents/StrapiRichText';
import { FormValues } from './CheckoutForm';

interface PaymentOptionsProps {
  touched: FormikTouched<FormValues>;
  errors: FormikErrors<FormValues>;
  values: FormValues;
  data: StrapiCheckoutSectionData;
}

export default function PaymentOptions({
  touched,
  errors,
  values,
  data,
}: PaymentOptionsProps) {
  const invoiceDisabled = !values.companyCheckout;

  useEffect(() => {
    if (invoiceDisabled) {
      values.paymentOption = 'klarna';
    }
  }, [invoiceDisabled, values]);

  const optionSelectedStyleClasses = (selected: boolean) =>
    selected ? 'border-2 border-blue-500 shadow-lg ' : 'border-hlr-gunmetal/25';
  const optionDisabledStyleClasses = (disabled: boolean) =>
    disabled
      ? 'opacity-50 cursor-not-allowed '
      : 'opacity-100 cursor-pointer hover:border-blue-500';

  return (
    <div className="flex flex-col">
      <h2>{data.fields_payment_option_title}</h2>
      <StrapiRichText
        additionalClassNames="text-body-hero mt-3 max-w-4xl md:mt-5"
        richText={data.fields_payment_option_description}
      />
      <div className="border-b border-hlr-gunmetal py-10">
        <strong className="text-xl">{data.fields_payment_option_header}</strong>
        {invoiceDisabled && (
          <div className="flex flex-wrap text-base font-light md:text-xl">
            <StrapiRichText
              richText={data.fields_payment_option_invoice_disabled_hint}
            />
          </div>
        )}
        <div className="flex flex-col gap-4 pt-6 md:flex md:flex-row">
          <label
            className={`flex w-full gap-4 rounded-lg border bg-custom-lightgrey p-4 transition-colors 
              ${optionDisabledStyleClasses(invoiceDisabled)}
              ${optionSelectedStyleClasses(values.paymentOption === 'invoice')}
              `}
          >
            <Field
              type="radio"
              name="paymentOption"
              value="invoice"
              disabled={invoiceDisabled}
            />
            <div className="flex flex-col gap-2">
              <h3>{data.fields_payment_option_invoice_label}</h3>
              <div className="font-light">
                {data.fields_payment_option_invoice_description}
              </div>
            </div>
          </label>
          <label
            className={`flex w-full cursor-pointer gap-4 rounded-lg border bg-custom-lightgrey p-4 transition-colors hover:border-blue-500
              ${optionSelectedStyleClasses(values.paymentOption === 'klarna')}
            `}
          >
            <Field type="radio" name="paymentOption" value="klarna" />
            <div className="flex flex-col gap-2">
              <h3>{data.fields_payment_option_klarna_label}</h3>
              <div className="font-light">
                {data.fields_payment_option_klarna_description}
              </div>
            </div>
          </label>
        </div>
        {touched.paymentOption && errors.paymentOption && (
          <span className="mt-1 text-custom-red">
            {errors.paymentOption}&nbsp;
          </span>
        )}
        {values.paymentOption === 'invoice' && (
          <PaperInvoiceCheckbox {...data} />
        )}
      </div>
    </div>
  );
}

function PaperInvoiceCheckbox(data: StrapiCheckoutSectionData) {
  const { shoppingCart, updateShoppingCart, displayVat } = useShoppingCart();
  const { selectedCustomer, authToken } = useUserContext();
  const strapiProduct = data.invoice_product?.data as
    | StrapiProductData
    | undefined;
  const handleChange = (addPaperInvoiceToCart: boolean) => {
    if (strapiProduct?.attributes?.product_id) {
      if (addPaperInvoiceToCart) {
        updateShoppingCart({
          type: ShoppingCartAction.ADD,
          payload: {
            amount: 1,
            id: strapiProduct.attributes?.product_id,
            name: strapiProduct.attributes?.cms_name,
          },
        });
      } else {
        updateShoppingCart({
          type: ShoppingCartAction.DELETE,
          payload: {
            amount: 0,
            id: strapiProduct.attributes?.product_id,
            name: strapiProduct.attributes?.cms_name,
          },
        });
      }
    }
  };
  const paperInvoiceInCart = useMemo(
    () =>
      shoppingCart.content.some(
        (e) => e.id === strapiProduct?.attributes?.product_id
      ),
    [shoppingCart.content, strapiProduct]
  );
  const [displayPrice, setDisplayPrice] = useState<string>('');

  useEffect(() => {
    async function fetchData() {
      const product = await getProductRequest(
        strapiProduct?.attributes?.product_id ?? '',
        selectedCustomer,
        authToken
      );
      product?.price_incl_vat &&
        product.price_excl_vat &&
        setDisplayPrice(
          parseFloat(
            displayVat ? product.price_incl_vat : product.price_excl_vat
          ).toFixed(2)
        );
    }
    fetchData().catch(console.error);
  }, [selectedCustomer, authToken, displayVat, strapiProduct]);

  return (
    <>
      {data.fields_paper_invoice_label && strapiProduct && (
        <div className="flex items-center gap-4 pt-10">
          <input
            className="form-checkbox"
            type="checkbox"
            id="paperInvoiceCheckbox"
            checked={paperInvoiceInCart}
            onChange={() => handleChange(!paperInvoiceInCart)}
          />
          <label
            htmlFor="paperInvoiceCheckbox"
            className="cursor-pointer font-raleway md:text-xl"
          >
            {data.fields_paper_invoice_label?.replace(
              '{kostnad}',
              displayPrice
            )}
          </label>
        </div>
      )}
    </>
  );
}
