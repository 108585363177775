import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { newOrder, newPaymentSession } from '@/modules/apiClient';
import Script from 'next/script';
import { useShoppingCart } from '@/context/shoppingCartContext';
import * as Sentry from '@sentry/nextjs';
import { useUserContext } from '@/context/UserContext';
import { toPerson } from '@/utils/checkoutUtils';
import {
  NewOrder,
  Customer,
  Order,
  NewPaymentSessionResponse,
  ShoppingCartProduct,
  NewPaymentSessionRequest,
  ValidatedDiscountCode,
} from '@/modules/apiTypes';
import { StrapiCheckoutSectionData } from '@/modules/strapiTypes';
import { FormValues, AddressValues } from './CheckoutForm';

interface KlarnaCheckoutProps {
  data: StrapiCheckoutSectionData;
  formValues: FormValues;
  handleFinalizedOrder(order: Order): void;
  setDisplayOrderCreationFailureModal: Dispatch<boolean>;
  discountCode: ValidatedDiscountCode | undefined;
}

export default function KlarnaCheckout({
  data,
  formValues,
  handleFinalizedOrder,
  setDisplayOrderCreationFailureModal,
  discountCode,
}: KlarnaCheckoutProps) {
  const { shoppingCart, displayVat, selectedShippingAlternative } =
    useShoppingCart();
  const { selectedCustomer, authToken } = useUserContext();

  const [paymentSession, setPaymentSession] =
    useState<NewPaymentSessionResponse>();

  const [klarnaPaymentAuthToken, setKlarnaPaymentAuthToken] = useState<
    string | undefined
  >(undefined);

  useEffect(() => {
    setKlarnaPaymentAuthToken(undefined);
    initPayment(
      shoppingCart.content,
      displayVat,
      setPaymentSession,
      authToken,
      selectedCustomer,
      selectedShippingAlternative?.id,
      discountCode?.discount_code
    );
  }, [
    shoppingCart,
    formValues,
    displayVat,
    authToken,
    selectedCustomer,
    selectedShippingAlternative?.id,
    discountCode?.discount_code,
  ]);

  useEffect(() => {
    initKlarnaContainer(paymentSession);
  }, [paymentSession]);

  const authorize = (paymentSession: NewPaymentSessionResponse) => {
    authorizePayment(formValues, paymentSession, setKlarnaPaymentAuthToken);
  };

  useEffect(() => {
    if (klarnaPaymentAuthToken && shoppingCart.content.length) {
      createNewOrder(
        klarnaPaymentAuthToken,
        shoppingCart.content,
        formValues,
        handleFinalizedOrder,
        authToken,
        selectedCustomer,
        setDisplayOrderCreationFailureModal,
        setKlarnaPaymentAuthToken,
        selectedShippingAlternative?.id,
        discountCode?.discount_code
      );
    }
  }, [
    authToken,
    displayVat,
    formValues,
    handleFinalizedOrder,
    klarnaPaymentAuthToken,
    selectedCustomer,
    selectedShippingAlternative?.id,
    setDisplayOrderCreationFailureModal,
    shoppingCart,
    discountCode,
  ]);

  return (
    <div className="mt-10 flex flex-col rounded-xl bg-custom-lightgrey">
      <Script
        src="https://x.klarnacdn.net/kp/lib/v1/api.js"
        async={true}
        onError={() => console.log('Error loading Klarna script')}
        onLoad={() => null}
      />
      <div id="klarna-payments-container" className="mb-2" />
      {paymentSession && !klarnaPaymentAuthToken && (
        <div
          className="mx-auto my-4 flex w-min cursor-pointer whitespace-nowrap rounded-full bg-hlr-gunmetal px-6 py-3 text-lg font-semibold text-white"
          onClick={() => authorize(paymentSession)}
        >
          {data.fields_klarna_checkout_button}
        </div>
      )}
    </div>
  );
}

function initKlarnaContainer(
  paymentSession: NewPaymentSessionResponse | undefined
) {
  if (paymentSession?.klarna_payment_session !== undefined) {
    // @ts-expect-errors -> Klarna is not known as a name because it's loaded by their script
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    Klarna.Payments.init({
      client_token: paymentSession.klarna_payment_session.client_token,
    });
    // @ts-expect-errors -> Klarna is not known as a name because it's loaded by their script
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    Klarna.Payments.load({
      container: '#klarna-payments-container',
      payment_method_category: 'klarna',
    });
  }
}

function initPayment(
  shoppingCartProducts: ShoppingCartProduct[],
  displayVat: boolean,
  setPaymentSession: Dispatch<
    SetStateAction<NewPaymentSessionResponse | undefined>
  >,
  authToken: string | undefined,
  selectedCustomer: Customer | undefined,
  shippingAlternative: number | undefined,
  discountCode: string | undefined
) {
  const newSession = async () => {
    const paymentSessionRequest: NewPaymentSessionRequest = {
      products: shoppingCartProducts,
      incl_vat: displayVat,
      shipping_alternative: shippingAlternative,
      discount_code: discountCode,
    };
    try {
      const paymentSession = await newPaymentSession(
        paymentSessionRequest,
        selectedCustomer,
        authToken
      );
      setPaymentSession(paymentSession);
    } catch (e) {
      Sentry.captureException(e);
    }
  };
  void newSession();
}

function authorizePayment(
  formValues: FormValues,
  paymentSession: NewPaymentSessionResponse,
  setAuthToken: Dispatch<SetStateAction<string | undefined>>
) {
  const deliveryAddress: PaymentAuthAddress = toPaymentAuthAddress(
    formValues.deliveryInfo
  );

  const billingAddress: PaymentAuthAddress | undefined =
    !formValues.useSameAddress
      ? toPaymentAuthAddress(formValues.billingInfo)
      : undefined;

  const authRequest: PaymentAuthRequestProps = {
    purchase_country: paymentSession.payment_session_details.purchase_country,
    purchase_currency: paymentSession.payment_session_details.purchase_currency,
    locale: paymentSession.payment_session_details.locale,
    billing_address: billingAddress ?? deliveryAddress,
    shipping_address: deliveryAddress,
    order_amount: 10,
    order_tax_amount: 0,
    order_lines: paymentSession.payment_session_details.order_lines,
  };

  // @ts-expect-errors -> Klarna is not known as a name because it's loaded by their script
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  Klarna.Payments.authorize(
    {
      payment_method_category: 'klarna',
      authRequest,
    },
    (res: PaymentAuthResponse) => {
      setAuthToken(res.authorization_token);
    }
  );
}

function createNewOrder(
  klarnaPaymentsAuthToken: string,
  products: ShoppingCartProduct[],
  formValues: FormValues,
  setOrder: (order: Order) => void,
  authToken: string | undefined,
  selectedCustomer: Customer | undefined,
  setDisplayOrderCreationFailureModal: Dispatch<boolean>,
  setKlarnaPaymentAuthToken: Dispatch<string>,
  shippingAlternative: number | undefined,
  discountCode: string | undefined
) {
  const awaitOrder = async () => {
    const orderRequest: NewOrder = {
      products: products,
      local_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      recipient: formValues.useSameAddress
        ? toPerson(formValues.billingInfo)
        : toPerson(formValues.deliveryInfo),
      buyer: toPerson(formValues.billingInfo),
      new_order_type: {
        type: 'new_klarna_order',
        authorization_token: klarnaPaymentsAuthToken,
      },
      incl_vat: selectedCustomer?.customer_type === 'private',
      invoice_reference: formValues.invoiceReference,
      email_for_digital_invoice: formValues.emailForDigitalInvoice,
      comment: formValues.comment,
      shipping_alternative: shippingAlternative,
      discount_code: discountCode,
    };
    const order = await newOrder(orderRequest, selectedCustomer, authToken);
    if (order) {
      setOrder(order);
    } else {
      setKlarnaPaymentAuthToken('');
      setDisplayOrderCreationFailureModal(true);
    }
  };
  void awaitOrder();
}

function toPaymentAuthAddress(
  addressValues: AddressValues
): PaymentAuthAddress {
  return {
    given_name: addressValues.firstName,
    family_name: addressValues.lastName,
    title: '',
    email: addressValues.email,
    phone: addressValues.phone,
    street_address: addressValues.address,
    street_address2: addressValues.address2,
    postal_code: addressValues.postNr,
    city: addressValues.city,
    region: '',
    country: addressValues.country,
  };
}

interface OrderLine {
  name: string;
  quantity: number;
  unit_price: number;
  total_amount: number;
  type: string;
  reference?: string | undefined;
  tax_rate?: number | undefined;
  total_discount_amount?: number | undefined;
  total_tax_amount?: number | undefined;
  image_url?: string | undefined;
  product_url?: string | undefined;
}

interface PaymentAuthAddress {
  given_name: string;
  family_name: string;
  email: string;
  title: string;
  street_address: string;
  street_address2: string;
  postal_code: string;
  city: string;
  region: string;
  phone: string;
  country: string;
}

interface PaymentAuthRequestProps {
  purchase_country: string;
  purchase_currency: string;
  locale: string;
  billing_address: PaymentAuthAddress;
  shipping_address?: PaymentAuthAddress;
  order_amount: number;
  order_tax_amount: number;
  order_lines: OrderLine[];
}

interface PaymentAuthResponse {
  approved: boolean;
  show_form: boolean;
  authorization_token?: string;
  error?: {
    invalid_fields: string[];
  };
}
