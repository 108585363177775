import Button from '@/components/general/Button';
import { TextField } from '@/components/general/input/TextField';
import { useEffect, useState } from 'react';
import {
  validateDiscountCode,
  validateDiscountCodeForCompanyCustomer,
} from '@/modules/apiClient';
import { useShoppingCart } from '@/context/shoppingCartContext';
import { ValidatedDiscountCode } from '@/modules/apiTypes';
import { useUserContext } from '@/context/UserContext';

interface DiscountCodeFieldProps {
  setValidDiscountCode(data?: ValidatedDiscountCode): void;
}

export function DiscountCodeField({
  setValidDiscountCode,
}: Readonly<DiscountCodeFieldProps>) {
  const [discountCode, setDiscountCode] = useState<string | undefined>(
    undefined
  );
  const [isCodeValid, setIsCodeValid] = useState<boolean>(true);
  const [isCodeSubmitted, setIsCodeSubmitted] = useState<boolean>(false);
  const shoppingCart = useShoppingCart();
  const { selectedCustomer, authToken } = useUserContext();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value.toUpperCase();
    setDiscountCode(newValue);
  };

  async function validateCode() {
    if (discountCode == undefined) {
      return;
    }

    const productIds: number[] =
      (shoppingCart?.shoppingCartProductAmount
        ?.flatMap((product) =>
          Array.from({ length: product.amount }, () => product.product.id)
        )
        .flat() as number[]) ?? [];

    if (productIds.length == 0) {
      setValidDiscountCode(undefined);
      return;
    }

    const result =
      selectedCustomer && authToken
        ? await validateDiscountCodeForCompanyCustomer(
            {
              discount_code: discountCode,
              product_ids: productIds,
            },
            selectedCustomer,
            authToken
          )
        : await validateDiscountCode({
            discount_code: discountCode,
            product_ids: productIds,
          });

    if (result != undefined) {
      setValidDiscountCode(result);
      setIsCodeValid(true);
      setIsCodeSubmitted(true);
    } else {
      setIsCodeSubmitted(true);
      setIsCodeValid(false);
    }
  }

  useEffect(() => {
    if (selectedCustomer) {
      void validateCode();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCustomer]);

  useEffect(() => {
    void validateCode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shoppingCart.shoppingCartProductAmount]);

  return (
    <div>
      <h2>Rabattkod</h2>

      <div className="flex items-center pt-3">
        <TextField
          label="Skriv in rabattkod:" // TODO fix translation
          name="discount_code"
          value={discountCode ?? ''}
          onChange={(e) => handleChange(e)}
          touched={discountCode != undefined}
          error={isCodeSubmitted && !isCodeValid ? 'Ogiltig rabattkod' : ''}
          noError={isCodeSubmitted && isCodeValid}
          disabled={isCodeSubmitted && isCodeValid}
        />

        <Button
          variantColor={isCodeSubmitted && isCodeValid ? 'grey' : 'green'}
          additionalClassNames={`ml-2 max-h-12 text-sm mt-7 ${
            isCodeSubmitted && isCodeValid ? 'pointer-events-none' : ''
          } ${
            isCodeSubmitted && isCodeValid
              ? 'pointer-events-none !important'
              : ''
          }`}
          label="Använd" // TODO fix translation
          onClick={() => void validateCode()}
          disabled={isCodeSubmitted && isCodeValid}
        />
      </div>
    </div>
  );
}
