import { useShoppingCart } from '@/context/shoppingCartContext';
import Cart from '@/components/general/Cart';
import { StrapiCheckoutSectionData } from '@/modules/strapiTypes';
import { TotalFormattedPrice } from '@/components/pageStore/TotalFormattedPrice';
import { StrapiRichText } from '@/strapiComponents/StrapiRichText';
import { ValidatedDiscountCode } from '@/modules/apiTypes';

interface CheckoutCartProps {
  data: StrapiCheckoutSectionData;
  discountCode: ValidatedDiscountCode | undefined;
}

export default function CheckoutCart({
  data,
  discountCode,
}: Readonly<CheckoutCartProps>) {
  const { displayVat, shoppingCartProductAmount } = useShoppingCart();

  return (
    <div className="pb-2 pt-10">
      <h2 className="mb-2">{data.fields_payment_option_header}</h2>
      <StrapiRichText
        additionalClassNames="text-body-hero mb-4 max-w-4xl md:mb-10"
        richText={data.fields_payment_option_description}
      />
      <Cart discountCode={discountCode} isCheckout={true} />
      <div className="mt-6 flex items-center justify-between border-t border-hlr-gunmetal pt-6">
        <div className="flex w-full flex-wrap items-center justify-between gap-8 text-xl font-light md:inline-flex md:text-2xl">
          <div className="flex items-center gap-2">
            <h2 className="mb-1.5 self-end font-light">
              {data.fields_total_price}
            </h2>
            <h2 className="font-mulish font-bold">
              <TotalFormattedPrice
                displayVat={displayVat}
                productAmounts={shoppingCartProductAmount}
                discountCode={discountCode}
                isCheckout={true}
              />
            </h2>
            <h2 className="mb-1.5 self-end font-light">
              {displayVat ? data.fields_vat_included : data.fields_vat_excluded}
            </h2>
          </div>
          <div className="flex items-center gap-2 self-end">
            <div className="self-end text-sm md:text-lg">
              {displayVat
                ? data.fields_vat_excluded
                : data.fields_vat_included + ':'}
            </div>
            <div className="font-mulish text-sm font-bold md:text-lg">
              <TotalFormattedPrice
                displayVat={!displayVat}
                productAmounts={shoppingCartProductAmount}
                discountCode={discountCode}
                isCheckout={true}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
