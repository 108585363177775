import { StrapiImageData } from '@/modules/strapiTypes';
import { ReactElement } from 'react';
import { StrapiRichText } from './StrapiRichText';
import { StrapiImage } from './StrapiImage';

interface SubmitStatusSuccessProps {
  successHeader: string;
  successDescription: unknown;
  successMedia?: StrapiImageData;
  resetSubmitSuccessful?(): void;
}

export function SubmitStatusSuccess({
  successHeader,
  successDescription,
  successMedia,
  resetSubmitSuccessful,
}: SubmitStatusSuccessProps): ReactElement | null {
  return (
    <div className="flex max-w-2xl flex-col gap-6">
      <div className="flex w-full flex-row items-center gap-4">
        {successMedia && (
          <StrapiImage className="h-10 w-10" strapiImage={successMedia} />
        )}
        <h2 className="text-custom-green">{successHeader}</h2>
        <button
          className="ml-auto text-lg md:text-xl"
          onClick={resetSubmitSuccessful}
        >
          &#x2715;
        </button>
      </div>
      {<StrapiRichText richText={successDescription} />}
    </div>
  );
}
