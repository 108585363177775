'use client';
import {
  StrapiFormSectionData,
  StrapiHeartstarterData,
  StrapiMountingOptionData,
  StrapiRentHeartstarterSectionData,
} from '@/modules/strapiTypes';
import { HeartstarterSection } from '../HeartstarterSection/HeartstarterSection';
import { BaseContactForm } from '@/strapiComponents/BaseContactForm';
import { RentHeartstarterForm } from './RentHeartstarterForm';
import { useMemo, useState } from 'react';
import SelectedProductAmounts from './SelectedProductAmounts';

export interface HeartstarterAmount {
  heartstarter: StrapiHeartstarterData;
  amount: number;
}

export interface MountingOptionAmount {
  mountingOption: StrapiMountingOptionData;
  amount: number;
}

export interface ProductIdAmount {
  id: string;
  amount: number;
}

export function RentHeartstarterSection(
  data: StrapiRentHeartstarterSectionData
) {
  const formData = data.form_section?.data as StrapiFormSectionData;

  const [heartstarterAmount, setHeartstarterAmount] =
    useState<HeartstarterAmount>();

  const [mountingOptionAmount, setMountingOptionAmount] =
    useState<MountingOptionAmount>();

  const heartstarterProductIdAmount: ProductIdAmount = useMemo(() => {
    return {
      id: heartstarterAmount?.heartstarter.attributes?.product?.data?.attributes
        ?.product_id as unknown as string,
      amount: heartstarterAmount?.amount ?? 0,
    };
  }, [heartstarterAmount]);

  const mountingOptionProductIdAmount: ProductIdAmount = useMemo(() => {
    return {
      id: mountingOptionAmount?.mountingOption.attributes?.product?.data
        ?.attributes?.product_id as unknown as string,
      amount: mountingOptionAmount?.amount ?? 0,
    };
  }, [mountingOptionAmount]);

  return (
    <div id={data.anchor}>
      {data.hs_mo_selection && (
        <HeartstarterSection
          data={data.hs_mo_selection}
          displayMode="rent"
          heartstarterAmount={heartstarterAmount}
          setHeartstarterAmount={setHeartstarterAmount}
          mountingOptionAmount={mountingOptionAmount}
          setMountingOptionAmount={setMountingOptionAmount}
          pageWidth={data.page_width}
        />
      )}
      {data.form_section && heartstarterAmount && (
        <BaseContactForm
          id="selectedHeartstarters"
          data={data.form_section.data as StrapiFormSectionData}
          backgroundColor={data.background_color}
          pageWidth={data.page_width}
        >
          <div>
            <SelectedProductAmounts
              productAmountHeader={data.chosen_products_header}
              heartstarterAmount={heartstarterAmount}
              setHeartstarterAmount={setHeartstarterAmount}
              mountingOptionAmount={mountingOptionAmount}
              setMountingOptionAmount={setMountingOptionAmount}
            />
            {formData.attributes?.form && (
              <RentHeartstarterForm
                data={formData.attributes.form}
                productIdAmounts={[
                  heartstarterProductIdAmount,
                  mountingOptionProductIdAmount,
                ]}
              />
            )}
          </div>
        </BaseContactForm>
      )}
    </div>
  );
}
