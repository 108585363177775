'use client';
import { StrapiHeroBannerItemData } from '@/modules/strapiTypes';
import { renderStrapiRichText } from '@/modules/strapiRichText';
import { StrapiImage } from '@/strapiComponents/StrapiImage';
import { StrapiButton } from '@/strapiComponents/StrapiButton';

export function HeroCard(props: StrapiHeroBannerItemData) {
  return (
    <div className="relative flex h-full w-full">
      <div className="m-auto w-full max-w-page-width-mx-80">
        <div className="px-default-sm pt-12 md:px-default-lg md:pt-0">
          <h1 className="text-white">{renderStrapiRichText(props.heading)}</h1>
          <div className="custom-divider-red mb-8" />
          <div className="text-body-hero mb-8 text-white">
            {renderStrapiRichText(props.description)}
          </div>
          <div className="flex flex-wrap gap-6">
            {props.buttons?.map((button, index) => (
              <StrapiButton key={button.id ?? index} {...button} />
            ))}
          </div>
        </div>
      </div>
      <div
        className={`absolute -top-24 bottom-0 -z-10 w-full object-cover ${
          props.show_overlay ? 'brightness-50' : 'brightness-100'
        }`}
      >
        {!!props.background?.media && (
          <StrapiImage
            className="h-full w-full object-cover"
            strapiImage={props.background.media}
            priority
            fill
            sizes="100vw"
          />
        )}
      </div>
    </div>
  );
}
