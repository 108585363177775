'use client';
import { StrapiArticleSectionData } from '@/modules/strapiTypes';
import {
  StrapiRichText,
  isValidStrapiRichText,
} from '@/strapiComponents/StrapiRichText';
import { RelatedProducts } from './RelatedProducts';
import { WebshopProduct } from '@/modules/apiTypes';
import { PageWidth } from '@/utils/strapiUtils';
import dayjs from 'dayjs';

interface ArticleSectionProps {
  data: StrapiArticleSectionData;
  preFetchedProducts: WebshopProduct[] | undefined;
  articleDate?: string | undefined;
  articleAuthor?: string | undefined;
}

export function ArticleSection({
  data,
  preFetchedProducts,
  articleDate,
  articleAuthor,
}: ArticleSectionProps) {
  return (
    <>
      <div
        id={data.anchor}
        className="mb:pt-14 mx-auto flex max-w-page-width-mx-80 flex-col-reverse gap-8 px-default-sm py-8 md:grid md:grid-cols-3 md:gap-24 md:px-default"
        style={{
          backgroundColor: data.background_color,
          maxWidth: data.page_width && PageWidth[data.page_width],
        }}
      >
        <div className="col-span-2">
          {(articleDate || data.date) && (
            <div className="mb-4 text-xs font-bold">
              {dayjs(articleDate ? articleDate : data.date).format(
                'DD MMM YYYY'
              )}
            </div>
          )}
          <h2 className="mb-4">{data.headline}</h2>

          <div className="space-y-3 divide-y divide-transparent text-sm font-light md:text-base">
            <StrapiRichText richText={data.content} />
          </div>
          {articleAuthor ||
            (data.author && (
              <div className="text-xs font-bold">
                - {articleAuthor ? articleAuthor : data.author ?? ''}
              </div>
            ))}
        </div>
        {isValidStrapiRichText(data.summary) && (
          <div>
            <p className="text-pre-header tracking-hlr-article-summary-title">
              {data.summary_header}
            </p>
            <div className="custom-divider-red" />
            <div className="space-y-6 text-sm font-light italic md:text-base">
              <StrapiRichText richText={data.summary} />
            </div>
            <div className="hidden md:block">
              {preFetchedProducts && preFetchedProducts.length > 0 && (
                <RelatedProducts
                  header={data.related_products_header}
                  storeLink={data.related_products_store_link}
                  products={preFetchedProducts}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
}
