'use client';
import { scrollToComponent, clearUrlFromHashtag } from '@/utils/generalUtils';
import { StrapiDetailHeaderSectionData } from '@/modules/strapiTypes';
import { StrapiImage } from '@/strapiComponents/StrapiImage';
import {
  StrapiRichText,
  isValidStrapiRichText,
} from '@/strapiComponents/StrapiRichText';
import { StrapiButton } from '@/strapiComponents/StrapiButton';
import { PageWidth } from '@/utils/strapiUtils';
import Link from 'next/link';

export function DetailHeaderSection(data: StrapiDetailHeaderSectionData) {
  return (
    <div
      id={data.anchor}
      className="flex flex-col bg-custom-darkgrey px-default-sm pb-8 pt-4 text-white md:px-default md:pb-16 md:pt-0"
      style={{ backgroundColor: data.background_color, color: data.text_color }}
    >
      <div
        className="mx-auto flex w-full max-w-page-width-mx-560 justify-between space-x-16"
        style={{ maxWidth: data.page_width && PageWidth[data.page_width] }}
      >
        <div className={`${data.image?.data ? 'md:w-1/2' : ''}`}>
          {data.back_button && (
            <Link
              className="mb-4 cursor-pointer text-lg font-bold hover:underline"
              href={data.back_button.url ?? ''}
              rel={data.back_button.rel}
              target={data.back_button.open_in_new_tab ? '_blank' : undefined}
            >
              <p>&larr;{data.back_button.display_name}</p>
            </Link>
          )}
          {data.pre_header && (
            <h2 className="text-pre-header mb-2 mt-6">{data.pre_header}</h2>
          )}
          <h1>{data.header}</h1>
          {isValidStrapiRichText(data.description) && (
            <div className="custom-divider-red mb-6" />
          )}
          {data.image?.data && (
            <div className="my-4 h-44 w-full md:hidden">
              <StrapiImage
                className="h-44 rounded-xl object-cover"
                strapiImage={data.image}
              />
            </div>
          )}
          <StrapiRichText
            additionalClassNames="mt-2 space-y-6 font-light leading-relaxed"
            richText={data.description}
          />
          {data.button && (
            <div
              onClick={() =>
                scrollToComponent(
                  data.button?.url ? clearUrlFromHashtag(data.button?.url) : '',
                  150
                )
              }
              className="mt-8 w-fit"
            >
              <StrapiButton
                display_text={data.button?.display_text}
                variant={data.button?.variant ?? 'transparent'}
              />
            </div>
          )}
        </div>
        {data.image?.data && (
          <div className="relative m-6 hidden w-1/2 max-w-2xl shrink md:block">
            <StrapiImage
              className="absolute h-full rounded-xl object-cover"
              strapiImage={data.image}
            />
          </div>
        )}
      </div>
    </div>
  );
}
