import { useMemo, useState } from 'react';
import { useShoppingCart } from '@/context/shoppingCartContext';
import { strapiGetCardPrice } from '@/utils/heartstarterUtils';
import { StrapiHeartstarterData, StrapiImageData } from '@/modules/strapiTypes';
import { WebshopProduct } from '@/modules/apiTypes';
import { StrapiImage } from '@/strapiComponents/StrapiImage';
import { StrapiRichText } from '@/strapiComponents/StrapiRichText';
import { HeartstarterAmount } from '@/strapiSections/RentHeartstarterSection/RentHeartstarterSection';

interface HeartstarterDetailsProps {
  heartstarter: StrapiHeartstarterData;
  selectButtonLabel: string | undefined;
  setSelectedHeartstarterDetails(value: undefined): void;
  setHeartstarterAmount(value: HeartstarterAmount): void;
  displayMode: 'rent' | 'buy';
  webshopProductMap: Map<string, WebshopProduct>;
}

export function HeartstarterDetails({
  heartstarter,
  selectButtonLabel,
  setSelectedHeartstarterDetails,
  setHeartstarterAmount,
  displayMode,
  webshopProductMap,
}: HeartstarterDetailsProps) {
  const { displayVat } = useShoppingCart();

  const firstImage = useMemo(
    () =>
      heartstarter?.attributes?.images?.data &&
      heartstarter?.attributes?.images?.data[0],
    [heartstarter?.attributes?.images?.data]
  );
  const [selectedImage, setSelectedImage] = useState<
    StrapiImageData | undefined
  >({ data: firstImage });
  const heartstarterProduct = webshopProductMap.get(
    heartstarter.attributes?.product?.data?.attributes?.product_id ?? ''
  );

  return (
    <div
      className="fixed inset-0 z-50 bg-custom-darkgrey/50 md:px-default"
      onClick={() => setSelectedHeartstarterDetails(undefined)}
    >
      <div
        className="mx-auto h-full max-w-page-width-mx-560 bg-white md:mt-16vh md:h-auto md:p-8"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="px-6 pt-4 md:p-0">
          <div className="flex flex-row justify-between pb-2">
            <h2>{heartstarter.attributes?.name}</h2>
            <div
              className="cursor-pointer md:text-2xl"
              onClick={() => setSelectedHeartstarterDetails(undefined)}
            >
              &#x2715;
            </div>
          </div>
          <div className="inset-0 flex h-full flex-row overflow-hidden pt-6 md:max-h-60vh">
            <div className="w-1/4 max-w-md">
              {selectedImage && (
                <StrapiImage
                  className="aspect-square w-full rounded-lg border object-contain"
                  strapiImage={selectedImage}
                />
              )}
              <div className="my-2 grid grid-cols-2 gap-2 md:grid-cols-4">
                {heartstarter.attributes?.images?.data?.map((image) => (
                  <div key={image.id}>
                    {image && (
                      <StrapiImage
                        className="aspect-square h-20 rounded-lg border object-cover"
                        onClick={() => setSelectedImage({ data: image })}
                        strapiImage={{ data: image }}
                      />
                    )}
                  </div>
                ))}
              </div>
            </div>
            <div className="max-h-70vh w-3/4 space-y-4 overflow-y-auto px-4 text-sm scrollbar-thin scrollbar-track-hlr-gunmetal/25 scrollbar-thumb-black scrollbar-thumb-rounded-full md:text-base">
              <StrapiRichText
                richText={heartstarter.attributes?.description_long}
              />
            </div>
          </div>
        </div>
        <div className="flex w-full flex-row items-center justify-between self-end bg-white px-6 pt-4 md:px-0">
          <h3 className="my-auto font-mulish md:text-32px">
            {strapiGetCardPrice(
              displayMode,
              heartstarter,
              heartstarterProduct,
              displayVat
            )}
          </h3>
          <p
            className="cursor-pointer self-center rounded-full bg-custom-blue px-6 py-2 text-lg text-white transition-opacity hover:opacity-60"
            onClick={() => {
              setSelectedHeartstarterDetails(undefined);
              setHeartstarterAmount({ heartstarter: heartstarter, amount: 1 });
            }}
          >
            {selectButtonLabel}
          </p>
        </div>
      </div>
    </div>
  );
}
