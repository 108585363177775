import { StrapiRichText } from '@/strapiComponents/StrapiRichText';
import { Dispatch } from 'react';

interface CancellationPaymentAgreementModalProps {
  setDisplayCancellationPaymentAgreementModal: Dispatch<boolean>;
  text: unknown;
}

export function CancellationPaymentAgreementModal({
  setDisplayCancellationPaymentAgreementModal,
  text,
}: CancellationPaymentAgreementModalProps) {
  return (
    <div
      className="fixed inset-0 z-50 bg-custom-darkgrey/50 p-default-sm md:px-default"
      onClick={() => setDisplayCancellationPaymentAgreementModal(false)}
    >
      <div
        className="mx-auto mt-16vh flex h-auto max-w-page-width-mx-680 bg-white p-8"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div>
          <StrapiRichText richText={text} />
        </div>
        <button
          onClick={() => setDisplayCancellationPaymentAgreementModal(false)}
          className="self-start text-xl md:text-3xl"
        >
          &#x2715;
        </button>
      </div>
    </div>
  );
}
