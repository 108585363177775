import Image from 'next/image';
import QuantitySelector from '@/components/general/QuantitySelector';
import {
  useShoppingCart,
  WebshopProductMap,
} from '@/context/shoppingCartContext';
import { strapiGetCardPrice } from '@/utils/heartstarterUtils';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import TrashIcon from '@/icons/trash.svg';
import { getProducts } from '@/modules/productMapCache';
import { useUserContext } from '@/context/UserContext';
import {
  HeartstarterAmount,
  MountingOptionAmount,
} from './RentHeartstarterSection';
import { StrapiImageData } from '@/modules/strapiTypes';
import { StrapiImage } from '@/strapiComponents/StrapiImage';

interface SelectedProductAmountsProps {
  productAmountHeader: string | undefined;
  heartstarterAmount: HeartstarterAmount | undefined;
  setHeartstarterAmount(value: HeartstarterAmount | undefined): void;
  mountingOptionAmount: MountingOptionAmount | undefined;
  setMountingOptionAmount(value: MountingOptionAmount | undefined): void;
}

export default function SelectedProductAmounts({
  productAmountHeader,
  heartstarterAmount,
  setHeartstarterAmount,
  mountingOptionAmount,
  setMountingOptionAmount,
}: SelectedProductAmountsProps) {
  const { displayVat } = useShoppingCart();
  const { selectedCustomer, authToken } = useUserContext();
  const [webshopProductMap, setWebshopProductMap] = useState<WebshopProductMap>(
    new Map()
  );

  const heartstarterId = heartstarterAmount?.heartstarter?.attributes?.product
    ?.data?.attributes?.product_id as unknown as string;
  const heartstarterProduct = webshopProductMap.get(heartstarterId);
  const heartstarterProductPrice =
    heartstarterAmount &&
    strapiGetCardPrice(
      'rent',
      heartstarterAmount.heartstarter,
      heartstarterProduct,
      displayVat
    );
  const firstHeartstarterImage = useMemo(
    () =>
      heartstarterAmount?.heartstarter?.attributes?.images?.data &&
      heartstarterAmount?.heartstarter?.attributes?.images?.data[0],
    [heartstarterAmount?.heartstarter?.attributes?.images?.data]
  );

  const mountingOptionId = mountingOptionAmount?.mountingOption.attributes
    ?.product?.data?.attributes?.product_id as unknown as string;
  const mountingOptionProduct = webshopProductMap.get(mountingOptionId);
  const mountingOptionProductPrice =
    mountingOptionAmount &&
    strapiGetCardPrice(
      'rent',
      mountingOptionAmount?.mountingOption,
      mountingOptionProduct,
      displayVat
    );

  useEffect(() => {
    const fetch = async () => {
      const prods = await getProducts(
        [heartstarterId, mountingOptionId],
        selectedCustomer,
        authToken
      );
      setWebshopProductMap(new Map(prods.map((p) => [p.id.toString(), p])));
    };
    void fetch();
  }, [authToken, heartstarterId, mountingOptionId, selectedCustomer]);

  const handleHSAmountChange = (newAmount: number) => {
    if (heartstarterAmount && newAmount >= 1) {
      setHeartstarterAmount({ ...heartstarterAmount, amount: newAmount });
    } else {
      setHeartstarterAmount(undefined);
    }
  };

  const handleMOAmountChange = (newAmount: number) => {
    if (mountingOptionAmount && newAmount >= 1) {
      setMountingOptionAmount({
        ...mountingOptionAmount,
        amount: newAmount,
      });
    } else {
      setMountingOptionAmount(undefined);
    }
  };

  return (
    <div id="selectedProductAmounts">
      <p className="text-2xl font-light md:text-3xl">
        {productAmountHeader ?? 'Valda produkter'}
      </p>
      <div className="my-6 flex flex-col space-y-6 border-y border-hlr-gunmetal py-6 md:space-y-4">
        {heartstarterAmount && (
          <LineItem
            name={heartstarterAmount.heartstarter.attributes?.name}
            image={{ data: firstHeartstarterImage }}
            price={heartstarterProductPrice}
            amount={heartstarterAmount.amount}
            handleAmountChange={handleHSAmountChange}
          />
        )}
        {mountingOptionAmount && (
          <LineItem
            name={mountingOptionAmount.mountingOption.attributes?.name}
            image={mountingOptionAmount.mountingOption.attributes?.image}
            price={mountingOptionProductPrice}
            amount={mountingOptionAmount.amount}
            handleAmountChange={handleMOAmountChange}
          />
        )}
      </div>
    </div>
  );
}

interface LineItemProps {
  image?: StrapiImageData | undefined;
  name?: string;
  price?: ReactNode;
  amount: number;
  handleAmountChange(n: number): void;
}

function LineItem({
  image,
  name,
  price,
  amount,
  handleAmountChange,
}: LineItemProps) {
  return (
    <div className="flex w-full flex-col justify-between md:flex-row">
      <div className="flex flex-row">
        {image && (
          <div className="h-16 w-24 shrink-0 grow-0 rounded bg-white">
            <StrapiImage
              className="h-16 w-24 shrink-0 grow-0 rounded border object-contain"
              strapiImage={image}
            />
          </div>
        )}
        <p className="h-min px-4 py-2 font-raleway text-sm font-bold md:px-6 md:text-lg">
          {name}
        </p>
      </div>
      <div className="flex justify-end gap-4">
        <div className="my-auto flex flex-row justify-between gap-4 whitespace-nowrap">
          <div className="custom-item-price mt-auto md:my-auto">{price}</div>
          <p className="pointer-events-none self-center text-gray-400">
            &#x2715;
          </p>
          <QuantitySelector
            amount={amount}
            handleIncrementClick={() => handleAmountChange(amount + 1)}
            handleDecrementClick={() => handleAmountChange(amount - 1)}
            handleAmountChange={(n) => handleAmountChange(n)}
          />
        </div>
        <button
          className="flex h-8 w-8 items-center justify-center self-center rounded-full text-lg transition-colors hover:bg-hlr-gunmetal/20 md:text-xl"
          onClick={() => handleAmountChange(0)}
        >
          <Image src={TrashIcon as string} alt="trash" width={24} height={24} />
        </button>
      </div>
    </div>
  );
}
