import Image from 'next/image';
import Link from 'next/link';
import PDFIcon from '@/icons/pdf-document-icon.svg';
import { StrapiArticleData } from '@/modules/strapiTypes';
import { StrapiImage } from '@/strapiComponents/StrapiImage';
import dayjs from 'dayjs';

export function ArticleCard(data: StrapiArticleData) {
  return (
    <Link href={`/artikel/${data.attributes?.slug ?? ''}`} className="h-full">
      <div className="h-full w-56 rounded-lg bg-white shadow-xl transition-opacity hover:opacity-60 md:w-72">
        {data.attributes?.image?.data ? (
          <StrapiImage
            className="h-32 rounded-t-lg object-cover md:h-44"
            strapiImage={data.attributes?.image}
          />
        ) : (
          <div className="relative h-32 md:h-44">
            <Image
              className="rounded-t-lg bg-custom-grey/50 object-contain p-4"
              src={PDFIcon as string}
              alt="Article image"
              fill
              sizes={`(max-width: 768px) 50vw, 25vw`}
            />
          </div>
        )}

        <div className="p-6">
          {data.attributes?.date && (
            <div className="mb-2 text-xs font-semibold">
              {dayjs(data.attributes.date).format('DD  MMM YYYY')}&nbsp;
            </div>
          )}
          <h3 className="hover:underline">{data.attributes?.title}</h3>
        </div>
      </div>
    </Link>
  );
}
