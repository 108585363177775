import { scrollToComponent } from '@/utils/generalUtils';
import { StrapiHeartstarterLocationData } from '@/modules/strapiTypes';
import { Dispatch } from 'react';
import { StrapiImage } from '@/strapiComponents/StrapiImage';
import { StrapiRichText } from '@/strapiComponents/StrapiRichText';
import {
  HeartstarterAmount,
  MountingOptionAmount,
} from '@/strapiSections/RentHeartstarterSection/RentHeartstarterSection';

interface LocationSelectionProps {
  header: string | undefined;
  description: unknown;
  locations: StrapiHeartstarterLocationData[];
  selectedLocation: number | undefined;
  setSelectedLocation: Dispatch<number | undefined>;
  setSelectedFeatures: Dispatch<number[]>;
  setHeartstarterAmount: Dispatch<HeartstarterAmount | undefined>;
  setMountingOptionAmount: Dispatch<MountingOptionAmount | undefined>;
}

export function LocationSelection({
  header,
  description,
  locations,
  selectedLocation,
  setSelectedLocation,
  setSelectedFeatures,
  setHeartstarterAmount,
  setMountingOptionAmount,
}: LocationSelectionProps) {
  return (
    <>
      <h3>{header}</h3>
      <StrapiRichText
        additionalClassNames="pt-1 text-sm font-light md:text-base"
        richText={description}
      />
      <div className="flex flex-wrap gap-4 pb-8 pt-4">
        {locations?.map((location) => {
          const locationName = location?.attributes?.title;
          const locationId = location?.id;
          return (
            <div
              key={locationId}
              className={`flex max-w-xs border-collapse cursor-pointer flex-row gap-2 rounded-lg border bg-white p-2 transition-opacity hover:opacity-60 md:gap-3 md:p-3 ${
                selectedLocation === locationId
                  ? ' border-2 border-blue-500 shadow-lg'
                  : ' border-custom-darkgrey '
              }
              `}
              onClick={() => {
                setSelectedLocation(location?.id);
                if (selectedLocation !== locationId) {
                  setSelectedFeatures([]);
                  setHeartstarterAmount(undefined);
                  setMountingOptionAmount(undefined);
                }
                scrollToComponent('featureSelection', 150);
              }}
            >
              {location?.attributes?.image && (
                <StrapiImage
                  className="h-14 w-14 shrink-0 md:h-20 md:w-20"
                  strapiImage={location?.attributes?.image}
                />
              )}
              <div>
                <p className="font-bold md:text-lg">{locationName}</p>
                <p className="text-sm md:text-base">
                  {location?.attributes?.description}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}
