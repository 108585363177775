'use client';
import { ReactElement, ReactNode } from 'react';
import { BlocksRenderer } from '@strapi/blocks-react-renderer';
import React from 'react';
import Link from 'next/link';
import Image from 'next/image';

export function renderStrapiRichText(s: unknown): ReactNode {
  return (
    <BlocksRenderer
      // @ts-expect-error RootNode type not exported from blocks-react-renderer
      content={s}
      blocks={{
        list: ({ children, format }) => {
          if (format === 'ordered') {
            return (
              <ol className="ml-8 list-decimal space-y-3 leading-relaxed">
                {children}
              </ol>
            );
          } else {
            return (
              <ul className="ml-8 list-disc space-y-3 leading-relaxed">
                {children}
              </ul>
            );
          }
        },
        paragraph: ({ children }) => {
          const childrenElement = children as ReactElement[];
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          return childrenElement[0].props.text.length > 0 ||
            childrenElement.length > 1 ? (
            <p>{children}</p>
          ) : (
            <div className="py-2"></div>
          );
        },
        link: ({ children, url }) => {
          let filteredUrl = url;
          const pattern: RegExp = /(?:https?|http):\/\/(.+)\.(tel|mailto)/g;
          const matches = pattern.exec(url);
          if (matches) {
            const [, content, type] = matches;
            filteredUrl = type + ':' + content;
          }

          const videoPattern: RegExp =
            /(https:\/\/www.youtube.com\/watch\?v=|https:\/\/youtu.be\/)(.+)/g;
          const videoMatches = videoPattern.exec(url);
          if (videoMatches) {
            const [, , videoId] = videoMatches;
            return (
              <div className="h-full bg-custom-lightgrey [&>*]:mx-auto">
                <lite-youtube videoid={videoId} />
              </div>
            );
          }
          return (
            <Link className="font-bold hover:underline" href={filteredUrl}>
              {children}
            </Link>
          );
        },
        image: (props) => (
          <div className="relative h-80">
            <Image
              src={props.image.url}
              alt={props.image.alternativeText ?? ''}
              className="object-contain"
              fill
              sizes={`(max-width: 768px) 100vw, 50vw`}
            />
          </div>
        ),
      }}
      modifiers={{
        bold: ({ children }) => <span className="font-bold">{children}</span>,
      }}
    />
  );
}
