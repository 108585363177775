'use client';
import React, { useMemo, useState } from 'react';
import {
  StrapiArticleData,
  StrapiPdfData,
  StrapiSupportSectionData,
  StrapiVideoData,
} from '@/modules/strapiTypes';
import ScrollCarousel from '@/components/general/ScrollCarousel';
import { StrapiRichText } from '@/strapiComponents/StrapiRichText';
import { getTags } from '@/utils/generalUtils';
import { ArticleCard } from './ArticleCard';
import { PdfCard } from './PdfCard';
import { VideoCard } from './VideoCard';
import { PageWidth } from '@/utils/strapiUtils';
import Button from '@/components/general/Button';

export function SupportSection(data: StrapiSupportSectionData) {
  const tags = useMemo(() => getTags(data.items), [data.items]);

  const [selectedTag, setSelectedTag] = useState<string>('');

  const filteredItems = selectedTag
    ? data.items?.filter((item) =>
        item?.tags?.some((tag) => tag.value === selectedTag)
      ) ?? []
    : data.items ?? [];

  return (
    <div id={data.anchor} style={{ backgroundColor: data.background_color }}>
      <div
        className="mx-auto max-w-page-width-mx-80"
        style={{ maxWidth: data.page_width && PageWidth[data.page_width] }}
      >
        {filteredItems && filteredItems.length > 0 && (
          <div className="py-8">
            <div className="px-default-sm md:px-default">
              <p className="text-pre-header mb-2">{data.title}</p>
              <StrapiRichText richText={data.header} />
              {data.show_tags && (
                <div className="flex flex-col pt-8 md:flex-row">
                  {data.tag_label && tags.length > 0 && (
                    <p className="my-auto shrink-0 pb-2 text-lg font-bold md:pb-0">
                      {data.tag_label}
                    </p>
                  )}
                  {tags.length > 0 && (
                    <div className="grid grid-cols-2 gap-4 md:ml-4 md:flex md:flex-wrap">
                      {tags.map((tag) => (
                        <div
                          key={tag}
                          onClick={() => {
                            tag === selectedTag
                              ? setSelectedTag('')
                              : setSelectedTag(tag);
                          }}
                          className="flex grow cursor-pointer md:grow-0"
                        >
                          {tag === selectedTag ? (
                            <Button
                              variantColor={'darkgrey'}
                              label={tag}
                              additionalClassNames={
                                'w-full text-center text-sm'
                              }
                            />
                          ) : (
                            <Button
                              variantColor={'transparent_darkgrey'}
                              label={tag}
                              additionalClassNames={
                                'w-full text-center text-sm'
                              }
                            />
                          )}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}
            </div>
            <ScrollCarousel gap={48} pt={40} pb={40}>
              {filteredItems.map((item) => (
                <div key={item.id}>
                  {item.media_type === 'article' && (
                    <ArticleCard
                      {...(item.article?.data as StrapiArticleData)}
                    />
                  )}
                  {item.media_type === 'video' && (
                    <VideoCard {...(item.video?.data as StrapiVideoData)} />
                  )}
                  {item.media_type === 'pdf' && (
                    <PdfCard {...(item.pdf?.data as StrapiPdfData)} />
                  )}
                </div>
              ))}
            </ScrollCarousel>
          </div>
        )}
      </div>
    </div>
  );
}
