import Link from 'next/link';
import { StrapiHeartstarterData, StrapiLink } from '@/modules/strapiTypes';
import { Dispatch } from 'react';
import { StrapiRichText } from '@/strapiComponents/StrapiRichText';
import { HeartstarterCard } from './HeartstarterCard';
import ScrollCarousel from '@/components/general/ScrollCarousel';
import { WebshopProduct } from '@/modules/apiTypes';
import { useShoppingCart } from '@/context/shoppingCartContext';
import { strapiGetCardPrice } from '@/utils/heartstarterUtils';
import {
  HeartstarterAmount,
  MountingOptionAmount,
} from '@/strapiSections/RentHeartstarterSection/RentHeartstarterSection';
import { PageWidth, PageWidthEnum } from '@/utils/strapiUtils';

interface HeartstarterCarouselProps {
  title: string | undefined;
  description: unknown;
  storeLink: StrapiLink | undefined;
  selectButtonLabel: string | undefined;
  selectedButtonLabel: string | undefined;
  heartstarters: StrapiHeartstarterData[];
  heartstarterAmount: HeartstarterAmount | undefined;
  setHeartstarterAmount: Dispatch<HeartstarterAmount | undefined>;
  setSelectedHeartstarterDetails: Dispatch<StrapiHeartstarterData | undefined>;
  setMountingOptionAmount: Dispatch<MountingOptionAmount | undefined>;
  displayMode: 'rent' | 'buy';
  webshopProductMap: Map<string, WebshopProduct>;
  pageWidth?: PageWidthEnum;
}

export function HeartstarterSelection({
  title,
  description,
  storeLink,
  selectButtonLabel,
  selectedButtonLabel,
  heartstarters,
  heartstarterAmount,
  setHeartstarterAmount,
  setSelectedHeartstarterDetails,
  setMountingOptionAmount,
  displayMode,
  webshopProductMap,
  pageWidth,
}: HeartstarterCarouselProps) {
  const { displayVat } = useShoppingCart();
  return (
    <>
      <div
        className="mx-auto max-w-page-width-mx-80 px-default-sm md:px-default"
        style={{ maxWidth: pageWidth && PageWidth[pageWidth] }}
      >
        <h3>{title}</h3>
        <div className="flex flex-col justify-between text-sm md:flex-row md:items-center md:pb-12 md:pt-4 md:text-base">
          <StrapiRichText
            additionalClassNames="pb-2 md:pb-0"
            richText={description}
          />
          <Link
            href={storeLink?.url ?? ''}
            rel={storeLink?.rel}
            target={storeLink?.open_in_new_tab ? '_blank' : '_self'}
          >
            <h3 className="hover:underline">
              {storeLink?.display_name} &rarr;
            </h3>
          </Link>
        </div>
      </div>
      <div className="mx-auto max-w-page-width-mx-80">
        <ScrollCarousel gap={40} pt={16} pb={16}>
          {heartstarters.map((heartstarter) => {
            const productId =
              heartstarter.attributes?.product?.data?.attributes?.product_id;
            const product = webshopProductMap.get(productId ?? '');
            return (
              <HeartstarterCard
                key={heartstarter.id}
                heartstarter={heartstarter}
                heartstarterAmount={heartstarterAmount}
                setHeartstarterAmount={setHeartstarterAmount}
                setMountingOptionAmount={setMountingOptionAmount}
                selectButtonLabel={selectButtonLabel}
                selectedButtonLabel={selectedButtonLabel}
                setSelectedHeartstarterDetails={setSelectedHeartstarterDetails}
                price={strapiGetCardPrice(
                  displayMode,
                  heartstarter,
                  product,
                  displayVat
                )}
              />
            );
          })}
        </ScrollCarousel>
      </div>
    </>
  );
}
