'use client';
import { useRouter } from 'next/router';
import { useState, useEffect, useRef } from 'react';
import { StrapiText } from '@/modules/strapiTypes';

export const mediumWidth = 768;

export function useWindowWidth(): number {
  const [windowWidth, setWindowWidth] = useState<number | undefined>(undefined);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    if (typeof window !== 'undefined') {
      setWindowWidth(window.innerWidth);
      window.addEventListener('resize', handleResize);
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', handleResize);
      }
    };
  }, []);

  return windowWidth ?? 0;
}

export function scrollToComponent(id: string, offset = 0) {
  const component = document.getElementById(id);
  if (component) {
    const topOffset = component.offsetTop - offset;
    window.scrollTo({
      top: topOffset,
      behavior: 'smooth',
    });
  }
}

export function usePreserveScroll() {
  const router = useRouter();

  const scrollPositions = useRef<Record<string, number>>({});
  const isBack = useRef(false);

  useEffect(() => {
    router.beforePopState(() => {
      isBack.current = true;
      return true;
    });

    const onRouteChangeStart = () => {
      const url = router.pathname;
      scrollPositions.current[url] = window.scrollY;
    };

    const onRouteChangeComplete = (url: string) => {
      if (isBack.current && scrollPositions.current[url]) {
        window.scroll({
          top: scrollPositions.current[url],
          behavior: 'auto',
        });
      }

      isBack.current = false;
    };

    router.events.on('routeChangeStart', onRouteChangeStart);
    router.events.on('routeChangeComplete', onRouteChangeComplete);

    return () => {
      router.events.off('routeChangeStart', onRouteChangeStart);
      router.events.off('routeChangeComplete', onRouteChangeComplete);
    };
  }, [router]);
}

export function getTags<
  T extends { tags?: StrapiText[] | undefined } | undefined
>(data: T[] | undefined): string[] {
  const allTags = data
    ? data.flatMap((item) => item?.tags).map((tag) => tag?.value ?? '')
    : [];
  const uniqueTags = Array.from(new Set<string>(allTags));
  return uniqueTags;
}

export function clearUrlFromHashtag(url: string): string {
  return url.replace('#', '');
}
