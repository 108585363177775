'use client';
import { StrapiFormData, TextInput } from '@/modules/strapiTypes';
import { Formik } from 'formik';
import { useMemo, useState } from 'react';
import * as Yup from 'yup';
import { TextField, TextArea } from '@/components/general/TextFields';
import Button from '@/components/general/Button';
import { getProducts } from '@/modules/productMapCache';
import { useRouter } from 'next/navigation';
import { ProductIdAmount } from './RentHeartstarterSection';
import { sendRentHeartstarterRequestV2 } from '@/modules/apiClient';

interface RentHeartstarterFormProps {
  data: StrapiFormData;
  productIdAmounts: ProductIdAmount[];
}

export function RentHeartstarterForm({
  data,
  productIdAmounts,
}: RentHeartstarterFormProps) {
  const router = useRouter();
  const contactFormInitialValues = getFormInitialValues(data.text_inputs ?? []);

  const validationSchema = useMemo(
    () =>
      getFormSchema(
        data.field_required_error_message ?? '',
        data.text_inputs ?? []
      ),
    [data.field_required_error_message, data.text_inputs]
  );

  const [isLoading, setIsLoading] = useState(false);

  const [submitStatus, setSubmitStatus] = useState<'success' | 'failed'>();

  const handleSubmit = async (values: typeof contactFormInitialValues) => {
    setSubmitStatus(undefined);
    try {
      const products = await getProducts(
        productIdAmounts.map((product) => product.id.toString()),
        undefined,
        undefined
      );

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { message, heartstarters: _, ...fields } = values;
      const requestValues = {
        message,
        heart_starters: products.map((product, index) => ({
          visma_id: product.visma_id,
          name: product.name,
          amount: productIdAmounts[index].amount,
        })),
        fields: Object.entries(fields).map(([name, value]) => ({
          name,
          value: value as string,
        })),
      };

      setIsLoading(true);
      await sendRentHeartstarterRequestV2(requestValues);
      setIsLoading(false);

      router.push('/hyra-hjartstartare/bekraftelse');
    } catch {
      setSubmitStatus('failed');
    }
  };

  return (
    <div className={isLoading ? 'blur-sm' : 'blur-none'}>
      <Formik
        validationSchema={validationSchema}
        initialValues={contactFormInitialValues}
        validateOnBlur={true}
        onSubmit={handleSubmit}
      >
        {({ submitForm, isSubmitting, setSubmitting }) => (
          <div className="flex flex-col gap-2">
            {data.text_inputs?.map((textInput) => (
              <TextField
                key={textInput.input_name}
                name={textInput.input_name ?? ''}
                label={textInput.label}
                isRequired={textInput.is_required}
                autoComplete={textInput.autocomplete_text}
                placeholder={textInput.placeholder}
              />
            ))}
            <div className="mb-2 flex-1">
              <TextArea
                name="message"
                label={data.message_label}
                placeholder={data.message_placeholder}
              />
            </div>
            <div className="flex items-center justify-end gap-4">
              {submitStatus === 'failed' && (
                <span className="text-red-500">{data.failed_message}</span>
              )}
              <Button
                variantColor={isSubmitting ? 'grey' : 'green'}
                label={data.submit_text ?? ''}
                onClick={() => {
                  if (!isSubmitting) {
                    void submitForm();
                    setSubmitting(false);
                  }
                }}
              />
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
}

function getFormSchema(
  fieldRequiredErrorMessage: string,
  textInputs: TextInput[]
) {
  const yupTextInputs = textInputs.map((textInput) => [
    textInput.input_name ?? '',
    textInput.is_required
      ? Yup.string().required(fieldRequiredErrorMessage)
      : Yup.string(),
  ]);

  return Yup.object({
    ...Object.fromEntries(yupTextInputs),
    message: Yup.string(),
  });
}

function getFormInitialValues(textInputs: TextInput[]): {
  [key: string]: string | string[];
  message: string;
} {
  const initialTextInputs = textInputs.map((textInput) => [
    textInput.input_name ?? '',
    '',
  ]);

  return {
    ...(Object.fromEntries(initialTextInputs) as Record<string, string>),
    message: '',
    heartstarters: [],
  };
}
