'use client';
import { StrapiFormData, TextInput } from '@/modules/strapiTypes';
import { Field, Formik } from 'formik';
import { useMemo, useState } from 'react';
import * as Yup from 'yup';
import { TextField, TextArea } from '@/components/general/TextFields';
import Button from '@/components/general/Button';
import { SubmitStatusSuccess } from '@/strapiComponents/SubmitStatusSuccess';
import { sendLimeContactRequestV2 } from '@/modules/apiClient';

function getFormSchema(
  fieldRequiredErrorMessage: string,
  textInputs: TextInput[]
) {
  const yupTextInputs = textInputs.map((textInput) => [
    textInput.input_name ?? '',
    textInput.is_required
      ? Yup.string().required(fieldRequiredErrorMessage)
      : Yup.string(),
  ]);

  return Yup.object({
    ...Object.fromEntries(yupTextInputs),
    interest: Yup.array().of(Yup.string()),
    message: Yup.string(),
  });
}

function getContactFormInitialValues(textInputs: TextInput[]): {
  [key: string]: string | string[];
  interest: string[];
  message: string;
} {
  const initialTextInputs = textInputs.map((textInput) => [
    textInput.input_name ?? '',
    '',
  ]);

  const interest: string[] = [];

  return {
    ...(Object.fromEntries(initialTextInputs) as Record<string, string>),
    interest: interest,
    message: '',
  };
}

export function ContactForm({
  text_inputs,
  checkboxes,
  checkboxes_label,
  message_label,
  message_placeholder,
  success_description,
  success_header,
  success_media,
  failed_message,
  field_required_error_message,
  submit_text,
}: StrapiFormData) {
  const contactFormInitialValues = getContactFormInitialValues(
    text_inputs ?? []
  );

  const validationSchema = useMemo(
    () => getFormSchema(field_required_error_message ?? '', text_inputs ?? []),
    [field_required_error_message, text_inputs]
  );

  const [isLoading, setIsLoading] = useState(false);
  const [submitSuccessful, setSubmitSucessful] = useState(false);

  if (!submitSuccessful && !isLoading) {
    void (async () => {
      setIsLoading(true);
      await new Promise((resolve) => setTimeout(resolve, 300));
      setIsLoading(false);
      setSubmitSucessful(true);
    })();
  }

  const [submitStatus, setSubmitStatus] = useState<'success' | 'failed'>();

  const handleSubmit = async (values: typeof contactFormInitialValues) => {
    const { interest, message, ...fields } = values;
    const requestData = {
      message,
      interest,
      fields: Object.entries(fields).map(([name, value]) => ({
        name,
        value: value as string,
      })),
      url: location.href,
    };
    await sendLimeContactRequestV2(requestData);
    setSubmitStatus('success');
  };

  return (
    <div>
      {submitStatus === 'success' ? (
        <SubmitStatusSuccess
          successHeader={success_header ?? ''}
          successDescription={success_description}
          successMedia={success_media}
          resetSubmitSuccessful={() => setSubmitStatus(undefined)}
        />
      ) : (
        <Formik
          validationSchema={validationSchema}
          initialValues={contactFormInitialValues}
          validateOnBlur={true}
          onSubmit={handleSubmit}
        >
          {({
            submitForm,
            errors,
            touched,
            isSubmitting,
            values,
            setSubmitting,
          }) => (
            <div className="flex flex-col gap-2">
              {text_inputs?.map((textInput) => (
                <TextField
                  key={textInput.input_name}
                  name={textInput.input_name ?? ''}
                  label={textInput.label}
                  isRequired={textInput.is_required}
                  autoComplete={textInput.autocomplete_text}
                  placeholder={textInput.placeholder}
                />
              ))}
              {checkboxes && checkboxes.length > 0 && (
                <>
                  <p className="mb-2">{checkboxes_label ?? '' + ' *'}</p>
                  <div className="flex flex-wrap gap-4">
                    {checkboxes.map((checkbox) => (
                      <label
                        key={checkbox.id}
                        className={`flex cursor-pointer items-center gap-2 rounded border p-4 transition-colors hover:border-blue-500 ${
                          values.interest.includes(checkbox.value ?? '')
                            ? 'border-blue-500'
                            : 'border-hlr-gunmetal/25'
                        }`}
                      >
                        <Field
                          type="checkbox"
                          className="form-checkbox h-4 w-4 rounded"
                          name="interest"
                          value={checkbox.value}
                        />
                        <span className="font-semibold">{checkbox.label}</span>
                      </label>
                    ))}
                  </div>
                  <span className="mt-1 text-custom-red">
                    {touched.interest && errors.interest}&nbsp;
                  </span>
                </>
              )}
              <div className="mb-2 flex-1">
                <TextArea
                  name="message"
                  label={message_label}
                  placeholder={message_placeholder}
                />
              </div>
              <div className="flex items-center justify-end gap-4">
                {submitStatus === 'failed' && (
                  <span className="text-red-500">{failed_message}</span>
                )}
                <Button
                  variantColor={isSubmitting ? 'grey' : 'green'}
                  label={submit_text ?? ''}
                  onClick={() => {
                    if (!isSubmitting) {
                      void submitForm();
                      setSubmitting(false);
                    }
                  }}
                />
              </div>
            </div>
          )}
        </Formik>
      )}
    </div>
  );
}
