import { Dispatch } from 'react';

interface DetailHistorySwitchProps {
  displayUserDetails: boolean;
  setValue: Dispatch<boolean>;
  userDetailsLabel: string;
  orderHistoryLabel: string;
  customerDetailsLabel: string;
  displayMode: number;
  setDisplayMode: Dispatch<number>;
}

export default function DetailHistorySwitch({
  userDetailsLabel,
  orderHistoryLabel,
  customerDetailsLabel,
  displayMode,
  setDisplayMode,
}: DetailHistorySwitchProps) {
  return (
    <div className="mt-20 flex w-full cursor-pointer flex-row rounded-xl border border-hlr-gunmetal text-center font-bold">
      <div
        className={`${
          displayMode === 0 ? 'bg-hlr-green text-white' : ''
        } flex w-1/3 justify-around rounded-l-xl py-3`}
        onClick={() => setDisplayMode(0)}
      >
        <p className="my-auto">{userDetailsLabel}</p>
      </div>
      <div
        className={`${
          displayMode === 1 ? 'bg-hlr-green text-white' : ''
        } flex w-1/3 justify-around  py-3`}
        onClick={() => setDisplayMode(1)}
      >
        <p className="my-auto">{customerDetailsLabel}</p>
      </div>
      <div
        className={`${
          displayMode === 2 ? 'bg-hlr-green text-white' : ''
        } flex w-1/3 justify-around rounded-r-xl py-3`}
        onClick={() => setDisplayMode(2)}
      >
        <p className="my-auto">{orderHistoryLabel}</p>
      </div>
    </div>
  );
}
